import {
  baseDomain,
  baseAppUrl
} from './utils'

const axios = require('axios')
const promiseRetry = require('promise-retry');


const CSRF_TOKEN_HEADER = 'X-Csrf-Token'

const VIBE_CHECK_BACKEND_BASE_URL = (process.env.NODE_ENV === 'production') ?
  `https://api.${baseDomain}`
  : 'http://localhost:8008';

const VIBE_CHECK_RESULTS_BASE_URL = `https://results.${baseDomain}`

const VIBE_CHECK_STATISTICS_BASE_URL = `https://statistics.${baseDomain}`

const VIBE_CHECK_SEARCH_BASE_URL = `https://search.${baseDomain}`

const requests = {
  get: (fullPath, options={retries:2}) => {
    return promiseRetry({retries: options.retries}, (retry, number) => {
      return axios.request({
        method: 'GET',
        url: fullPath,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: 'X-Csrf-Token'
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err.response.data;
        });
    });
  },
  post: (fullPath, body, options={retries:2}) => {
    return promiseRetry({retries: options.retries}, (retry, number) => {
      return axios.request({
        method: 'POST',
        url: fullPath,
        data: body,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: 'X-Csrf-Token'
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err.response ? err.response.data : {error_code: "default_error_message"};
        });
    });
  },
  getMetadata: (fullPath, options={retries:2, useCache:false}) => {
    // Note: these headers ensure we always fetch the most up-to-date
    // version of the requested JSON file. 
    let cacheConfig = {}
    if (!(options.useCache)) {
      cacheConfig["cache"] = "no-cache"
    }

    return promiseRetry({retries: options.retries}, (retry, number) => {
      return fetch(fullPath, cacheConfig).then(
        function (result) { // success handler
          if (!(result.ok)) {
            throw new Error("Invalid Metadata Response")
          }
          return result.json()
        },
        function (err) { // failure handler
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err;

        }
     ).catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err;
        });
    });
  },
};

const VibeCheckBackend = {
  getSpotifyTrackSearchResults: ({query}) =>
    requests.get(`${VIBE_CHECK_SEARCH_BASE_URL}/${query}`, {retries:2}),
  getTrackMetadata: ({vibe_check_url}) =>
    requests.post(`${VIBE_CHECK_BACKEND_BASE_URL}/track/metadata`, {vibe_check_url}, {retries:2}),
  analyzeTrackVibration: ({vibecheck_token}) =>
    requests.post(`${VIBE_CHECK_BACKEND_BASE_URL}/track/vibration`, {vibecheck_token}, {retries:2}),
  getCachedVibecheckResult: ({short_code}) =>
    requests.getMetadata(`${VIBE_CHECK_RESULTS_BASE_URL}/${short_code}.json`, {useCache: true}),
  getVibecheckStatistics: () =>
    requests.getMetadata(`${VIBE_CHECK_STATISTICS_BASE_URL}`, {useCache: false}),
  getI18NFile: ({language_code}) =>
    requests.getMetadata(`${baseAppUrl}/i18n/${language_code}.json`, {useCache: false}),
  saveEmailSubscription: ({email_address, first_name, language_code}) =>
    requests.post(`${VIBE_CHECK_BACKEND_BASE_URL}/subscription/create`, {email_address, first_name, language_code}, {retries:2}),
  // Mocked for now. Todo: Implement Actual Endpoints.
  // getSpotifyTrackMetadata: ({vibe_check_url}) =>
  //   Promise.resolve({"artist_name": "Foals", "artist_profile_image_url": "https://i.scdn.co/image/ab6761610000f1782c64dee66a7e41971458a4a8", "album_image_url": "https://i.scdn.co/image/ab67616d00001e02bece64c47957bcf1da2e40d3", "track_name": "Neptune"}),
  // analyzeTrackVibration: ({artist_name, track_name}) =>
  //   Promise.resolve({"share_url": "https://checkthevibe.app/s/xxxx", "top_nervous_system_states": [{"name": "play", "probability": 0.17895061812137425}, {"name": "parasympathetic", "probability": 0.15538306628695353}, {"name": "sympathetic", "probability": 0.11608109767406362}]}),
  // getCachedVibecheckResult: ({short_code}) =>
  //   Promise.resolve({"spotify_track_metadata": {"artist_name": "KIDS SEE GHOSTS", "track_name": "Cudi Montage", "artist_profile_image_url": "https://i.scdn.co/image/ab6761610000f17822c693c66a5494d88194d543", "album_image_url": "https://i.scdn.co/image/ab67616d00001e02013c00ee367dd85396f79c82"}, "vibe_check_metadata": {"share_url": "https://checkthevibe.app/s/xxxx", "top_nervous_system_states": [{"name": "play", "probability": 0.27895061812137425}, {"name": "parasympathetic", "probability": 0.25538306628695353}, {"name": "sympathetic", "probability": 0.16081019767406362}]}, "created_at": 1633804080})
}


export default {
  VibeCheckBackend,
};
