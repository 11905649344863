import React from 'react'

import {i18n} from '../../utils'
import { CSSTransition } from 'react-transition-group';

const errorMessageStyle = {color: "#fff", textAlign: "center", marginTop: 5}

const ErrorPanel = ({
  // State
  show,
  message,
  onClose,
  hideCloseButton,
  showRetryButton,
  retryButtonText,
  retryButtonLoadingText,
  isRetrying,
  onRetry,
}) => {
  const retryText = retryButtonText || i18n.retry || "Retry"
  const retryLoadingText = retryButtonLoadingText || i18n.loading || "Loading..."
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fade-in"
      unmountOnExit
      appear
    >
      <div 
        onClick={e => {
          e.stopPropagation()
        }} 
        style={{pointerEvents: "all", display: "inline-block", borderRadius: "6px", boxShadow: "0px 0px 0.5px 1.5px #ffffff", backgroundColor: "#ffb4b4", padding: 15, margin: "10px auto", maxWidth: "min(400px, 80%)"}}
      >
        {(!hideCloseButton) && (
          <span
            className="close"
            onClick={onClose}
          >
            &times;
          </span>
        )}
        <div style={errorMessageStyle}>
          {message}
        </div>
        {(showRetryButton) && (
          <button 
            onClick={e => {
              e.stopPropagation()
              onRetry()
            }}
            style={{display: "flex", alignItems: "center", margin: "10px auto", border: "none", backgroundColor: "#353F6A", color: "white", borderRadius: 4, fontFamily: "Varela Round", fontSize: 18}}
          >
            <div style={{height: 30, width: 30}}>
              <svg className="authentique-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100"><g transform="translate(50 50) scale(-0.69 0.69) rotate(-180) translate(-50 -50)" style={{fill:"#ffffff"}}><svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve"><g><g><path d="M49.6,87v7.6c0,2.2,2.4,3.6,4.3,2.4l20.5-12.8c1.8-1.1,1.8-3.7,0-4.8L54,66.7c-1.9-1.2-4.3,0.2-4.3,2.4v7.6    c-0.1,0-0.2,0-0.2,0c-17.1-0.3-31.2-14.5-31.3-31.6c-0.2-18.5,15.4-33.4,34.1-32.1c15.4,1,28.1,13.3,29.6,28.7    c0.8,8.1-1.4,15.6-5.7,21.6c-1,1.4-0.6,3.3,0.9,4.2l3.9,2.3c1.3,0.8,3,0.4,3.9-0.8c4.8-6.8,7.6-15.1,7.6-24.1    c0-24.4-20.7-44-45.5-42.2C26.1,4.2,9.4,20.9,7.8,41.5C6,66.2,25.4,86.8,49.6,87z"></path></g></g></svg></g></svg>
            </div>
            <div style={{paddingRight: 5}}>
              {isRetrying ? retryLoadingText : retryText}
            </div>
          </button>
        )}
      </div>
    </CSSTransition>
  )
}

export default ErrorPanel