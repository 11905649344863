export const GA_LABEL_LOADED_VIBE_CHECK_PAGE = "Loaded Vibe Check Page"
export const GA_LABEL_FOCUSED_SPOTIFY_SEARCH_BAR = "Focused Search Bar"
export const GA_LABEL_SEARCH_QUERY_TRIGGERED = "Search Query Triggered"
export const GA_LABEL_SEARCH_QUERY_COMPLETED = "Search Query Completed"
export const GA_LABEL_VIBE_CHECK_TRIGGERED = "Vibe Check Triggered"
export const GA_LABEL_VIBE_CHECK_RETRIEVED_SPOTIFY_METADATA = "Retrieved Track Metadata"
export const GA_LABEL_VIBE_CHECK_TRIGGERED_ANALYZE_TRACK_LYRICS = "Triggered Analyze Lyrics"
export const GA_LABEL_VIBE_CHECK_SUCCEEDED = "Vibe Check Succeeded"

export const GA_LABEL_CLICKED_SHARE_BUTTON = "Clicked Share Button"
export const GA_LABEL_CLICKED_HOME_BUTTON = "Clicked Home Button"
export const GA_LABEL_CLICKED_SHOW_DETAILS_BUTTON = "Clicked Show Details Button"
export const GA_LABEL_CLICKED_SHOW_RESULTS_INFO_BUTTON = "Clicked Show Results Info Button"

export const GA_LABEL_CLICKED_RESULTS_PANEL_INFO_BUTTON = "Clicked Results Panel Info Button"
export const GA_LABEL_CLICKED_ABOUT_MODAL_LEVELS_BUTTON = "Clicked About Modal Levels Button"
export const GA_LABEL_CLICKED_ABOUT_MODAL_CREATORS_BUTTON = "Clicked About Modal Creators Button"
export const GA_LABEL_CLICKED_EMAIL_SUBSCRIPTION_BUTTON = "Clicked Email Subscription Button"

export const GA_LABEL_CLICKED_SELECT_LANGUAGE = "Clicked Select Language"

export const GA_LABEL_CREATED_EMAIL_SUBSCRIPTION = "Created Email Subscription"