import {
  COMMON_APP_NAME,
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_SAFE_AREA_INSETS,
  COMMON_VIBE_CHECK_SEARCH_TEXT,
  COMMON_VIBE_CHECK_PROGRESS,
  COMMON_MAX_VIBE_CHECK_PROGRESS,
  COMMON_SHOW_DESCRIPTION,
  COMMON_SHOW_TRANSCENDENCE,
  COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE,
  COMMON_BOTTOM_SUCCESS_MESSAGE,
  COMMON_CACHED_RESULT_SHORT_CODE,
  COMMON_FOOTER_STATISTICS,
  COMMON_PAGE_IS_VISIBLE,
  COMMON_SHOW_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL,
  COMMON_SHOW_SELECT_LANGUAGE_MODAL,
  COMMON_SHOW_EMAIL_SUBSCRIPTION_MODAL,
  COMMON_SELECTED_TRACK_ID,
} from '../constants/commonState'

import {
  REQ_FETCH_TRACK_SEARCH_RESULTS,
  REQ_RUN_VIBECHECK,
  REQ_FETCH_SPOTIFY_TRACK_METADATA,
  REQ_ANALYZE_TRACK_VIBRATION,
  REQ_FETCH_CACHED_VIBECHECK_RESULT,
  REQ_FETCH_VIBECHECK_STATISTICS,
  REQ_FETCH_I18N,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS,
} from '../constants/requestStates'

import {
  COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_VIBE_CHECK_PROGRESS,
  COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS,
  COMMON_UPDATE_SHOW_DESCRIPTION,
  COMMON_UPDATE_SHOW_TRANSCENDENCE,
  COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE,
  COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE,
  COMMON_UPDATE_CACHED_RESULT_SHORT_CODE,
  COMMON_UPDATE_STATISTICS,
  COMMON_UPDATE_PAGE_IS_VISIBLE,
  COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL,
  COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL,
  COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL,
  COMMON_UPDATE_SELECTED_TRACK_ID,
  COMMON_RESET_STATE,
} from '../constants/actionTypes'

import {
  SetGlobalI18N,
  isIOSSafari,
  isIOSStandalone,
  isIOSDevice,
  isAndroidDevice,
} from '../utils'

const safeAreaInsets = require('safe-area-insets')

const defaultCommonState = {
  [COMMON_APP_NAME]: 'vibecheck.life',
  [COMMON_VIBE_CHECK_SEARCH_TEXT]: "",
  [COMMON_VIBE_CHECK_PROGRESS]: 0.0,
  [REQ_FETCH_TRACK_SEARCH_RESULTS]: {state: REQUEST_UNSTARTED},
  [REQ_RUN_VIBECHECK]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_SPOTIFY_TRACK_METADATA]: {state: REQUEST_UNSTARTED},
  [REQ_ANALYZE_TRACK_VIBRATION]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_CACHED_VIBECHECK_RESULT]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_VIBECHECK_STATISTICS]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_I18N]: {state: REQUEST_UNSTARTED},
  [COMMON_SAFE_AREA_INSETS]: safeAreaInsets,
  [COMMON_INNER_HEIGHT]: (document.body.clientHeight || window.innerHeight),
  [COMMON_INNER_WIDTH]: (document.body.clientWidth || window.innerWidth),
  [COMMON_SHOW_DESCRIPTION]: false,
  [COMMON_SHOW_TRANSCENDENCE]: false,
  [COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE]: false,
  [COMMON_BOTTOM_SUCCESS_MESSAGE]: "",
  [COMMON_CACHED_RESULT_SHORT_CODE]: "",
  [COMMON_FOOTER_STATISTICS]: {"vibe_checks_total": "...", "vibe_checks_24h": "...", "users_total": "..."},
  [COMMON_PAGE_IS_VISIBLE]: true,
  [COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN]: true,
  [COMMON_REFRESH_STATISTICS_COUNTDOWN]: 7,
  [COMMON_SHOW_SELECT_LANGUAGE_MODAL]: false,
  [COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL]: false,
  [COMMON_SELECTED_TRACK_ID]: ""
};

export default (state = defaultCommonState, action) => {
  switch (action.type) {
    case COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT:
      return {...state, [COMMON_VIBE_CHECK_SEARCH_TEXT]: action.payload}
    case COMMON_UPDATE_VIBE_CHECK_PROGRESS:
      return {...state, [COMMON_VIBE_CHECK_PROGRESS]: action.payload}
    case COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS:
      return {...state, [COMMON_MAX_VIBE_CHECK_PROGRESS]: action.payload}
    case COMMON_UPDATE_INNER_HEIGHT:
      return {...state, [COMMON_INNER_HEIGHT]: action.payload}
    case COMMON_UPDATE_SAFE_AREA_INSETS:
      return {...state, [COMMON_SAFE_AREA_INSETS]: action.payload}
    case COMMON_UPDATE_SHOW_DESCRIPTION:
      return {...state, [COMMON_SHOW_DESCRIPTION]: action.payload}
    case COMMON_UPDATE_SHOW_TRANSCENDENCE:
      return {...state, [COMMON_SHOW_TRANSCENDENCE]: action.payload}
    case COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE:
      return {...state, [COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE]: action.payload}
    case COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE:
      return {...state, [COMMON_BOTTOM_SUCCESS_MESSAGE]: action.payload}
    case COMMON_UPDATE_CACHED_RESULT_SHORT_CODE:
      return {...state, [COMMON_CACHED_RESULT_SHORT_CODE]: action.payload}
    case COMMON_UPDATE_STATISTICS:
      return {...state, [COMMON_FOOTER_STATISTICS]: action.payload}
    case COMMON_UPDATE_PAGE_IS_VISIBLE:
      return {...state, [COMMON_PAGE_IS_VISIBLE]: action.payload}
    case COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN:
      return {...state, [COMMON_SHOW_REFRESH_STATISTICS_COUNTDOWN]: action.payload}
    case COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN:
      return {...state, [COMMON_REFRESH_STATISTICS_COUNTDOWN]: action.payload}
    case COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL:
      return {...state, [COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL]: action.payload}
    case COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL:
      return {...state, [COMMON_SHOW_SELECT_LANGUAGE_MODAL]: action.payload}
    case COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL:
      return {...state, [COMMON_SHOW_EMAIL_SUBSCRIPTION_MODAL]: action.payload}
    case COMMON_UPDATE_SELECTED_TRACK_ID:
      return {...state, [COMMON_SELECTED_TRACK_ID]: action.payload}
    case COMMON_UPDATE_REQUEST_STATE: {
      return {...state, [action.key]: action.payload}
    }
    case COMMON_RESET_STATE:
      return defaultCommonState
    default:
      return state;
  }
};
