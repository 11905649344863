export const COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT = 'COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT'
export const COMMON_TRIGGER_REQUEST = 'COMMON_TRIGGER_REQUEST__'
export const COMMON_UPDATE_REQUEST_STATE = 'COMMON_UPDATE_REQUEST_STATE'
export const COMMON_RESET_STATE = 'COMMON_RESET_STATE'
export const COMMON_UPDATE_INNER_HEIGHT = 'COMMON_UPDATE_INNER_HEIGHT'
export const COMMON_UPDATE_INNER_WIDTH = 'COMMON_UPDATE_INNER_WIDTH'
export const COMMON_UPDATE_SAFE_AREA_INSETS = 'COMMON_UPDATE_SAFE_AREA_INSETS'
export const COMMON_UPDATE_VIBE_CHECK_PROGRESS = 'COMMON_UPDATE_VIBE_CHECK_PROGRESS'
export const COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS = 'COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS'
export const COMMON_UPDATE_SHOW_DESCRIPTION = 'COMMON_UPDATE_SHOW_DESCRIPTION' 
export const COMMON_UPDATE_SHOW_TRANSCENDENCE = 'COMMON_UPDATE_SHOW_TRANSCENDENCE'
export const COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE = 'COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE'
export const COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE = 'COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE'
export const COMMON_UPDATE_CACHED_RESULT_SHORT_CODE = 'COMMON_UPDATE_CACHED_RESULT_SHORT_CODE'
export const COMMON_UPDATE_STATISTICS = 'COMMON_UPDATE_STATISTICS'
export const COMMON_UPDATE_PAGE_IS_VISIBLE = 'COMMON_UPDATE_PAGE_IS_VISIBLE'
export const COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN = 'COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN'
export const COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN = 'COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN'
export const COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL = 'COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL'
export const COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL = 'COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL'
export const COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL = 'COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL'
export const COMMON_UPDATE_SELECTED_TRACK_ID = 'COMMON_UPDATE_SELECTED_TRACK_ID'
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';