import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect, withRouter } from 'react-redux'
import { Redirect } from 'react-router-dom';
import {
  commonUpdateVibeCheckSearchText,
  commonTriggerRequest,
  commonUpdateInnerHeight,
  commonUpdateInnerWidth,
  commonUpdateSafeAreaInsets,
  commonUpdateShowDescription,
  commonUpdateShowTranscendence,
  commonUpdateBottomSuccessMessage,
  commonUpdateCachedResultShortCode,
  commonUpdateSelectedTrackId,
  commonUpdateRequestState,
  commonUpdatePageIsVisible,
  commonUpdateShowNervousSystemInfoModal,
  commonUpdateShowSelectLanguageModal,
  commonUpdateShowEmailSubscriptionModal,
  resetCommon,
} from '../actions/common';


import {
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  REQUEST_FETCHING,
} from '../constants/requestStates';

import {
  REQ_FETCH_TRACK_SEARCH_RESULTS,
  REQ_RUN_VIBECHECK,
  REQ_FETCH_SPOTIFY_TRACK_METADATA,
  REQ_ANALYZE_TRACK_VIBRATION,
  REQ_FETCH_CACHED_VIBECHECK_RESULT,
  REQ_FETCH_VIBECHECK_STATISTICS,
  REQ_FETCH_I18N,
} from '../constants/requestTypes'

import {
  COMMON_VIBE_CHECK_SEARCH_TEXT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_VIBE_CHECK_PROGRESS,
  COMMON_SHOW_DESCRIPTION,
  COMMON_SHOW_TRANSCENDENCE,
  COMMON_FOOTER_STATISTICS,
  COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE,
  COMMON_BOTTOM_SUCCESS_MESSAGE,
  COMMON_SHOW_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL,
  COMMON_SHOW_SELECT_LANGUAGE_MODAL,
  COMMON_SHOW_EMAIL_SUBSCRIPTION_MODAL,
} from '../constants/commonState'

import VibeCheckPage from '../components/VibeCheckPage';
import AboutPage from '../components/AboutPage'
import LevelsPage from '../components/LevelsPage'
import AppLoadingPageContainer from '../containers/AppLoadingPageContainer'

import {
  loading_i18n,
  language,
  hideLoadingScreen,
  getLanguageCodeFromCookies,
  LanguageCodeEnglish,
  UpdateLanguageCode,
} from '../utils'

import { store } from '../store';
import { replace } from 'react-router-redux';
import smoothscroll from 'smoothscroll-polyfill';

import { Plugins } from '@capacitor/core';

const { App, KeepAwake, PushNotifications } = Plugins

const safeAreaInsets = require('safe-area-insets')

class MainAppContainer extends Component {

  componentWillMount() {
    this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets))
    const innerHeight = document.body.clientHeight || window.innerHeight
    document.body.height = innerHeight
    this.props.dispatch(commonUpdateInnerHeight(innerHeight))

    const innerWidth = document.body.clientWidth || window.innerWidth
    document.body.width = innerWidth
    this.props.dispatch(commonUpdateInnerWidth(innerWidth))
  }

  async componentDidMount() {
    window.addEventListener('resize', () => {
      const innerHeight = document.body.clientHeight || window.innerHeight
      document.body.height = innerHeight
      this.props.dispatch(commonUpdateInnerHeight(innerHeight))

      const innerWidth = document.body.clientWidth || window.innerWidth
      document.body.width = innerWidth
      this.props.dispatch(commonUpdateInnerWidth(innerWidth))
    })

    const innerHeight = document.body.clientHeight || window.innerHeight
    document.body.height = innerHeight
    this.props.dispatch(commonUpdateInnerHeight(innerHeight))

    const innerWidth = document.body.clientWidth || window.innerWidth
    document.body.width = innerWidth
    this.props.dispatch(commonUpdateInnerWidth(innerWidth))


    window.addEventListener("pageshow", () => {
      const innerHeight = document.body.clientHeight || window.innerHeight
      document.body.height = innerHeight
      this.props.dispatch(commonUpdateInnerHeight(innerHeight))

      const innerWidth = document.body.clientWidth || window.innerWidth
      document.body.width = innerWidth
      this.props.dispatch(commonUpdateInnerWidth(innerWidth))
    })

    // Check for frontend updates whenever app is shown.
    document.addEventListener("visibilitychange", e => {
      const pageIsVisible = (!document.hidden)
      this.props.dispatch(commonUpdatePageIsVisible(pageIsVisible))
      if (pageIsVisible) {
        this.props.dispatch(commonTriggerRequest(REQ_FETCH_VIBECHECK_STATISTICS))
      }
      const innerHeight = document.body.clientHeight || window.innerHeight
      document.body.height = innerHeight
      this.props.dispatch(commonUpdateInnerHeight(innerHeight))

      const innerWidth = document.body.clientWidth || window.innerWidth
      document.body.width = innerWidth
      this.props.dispatch(commonUpdateInnerWidth(innerWidth))
    })

    smoothscroll.polyfill();

    safeAreaInsets.onChange(updatedValue => this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets)))
    this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets))

    if (this.props.match.params.resultShortCode) {
      this.props.dispatch(commonUpdateCachedResultShortCode(this.props.match.params.resultShortCode))
      this.props.dispatch(commonTriggerRequest(REQ_FETCH_CACHED_VIBECHECK_RESULT))
    }

    const languageCode = getLanguageCodeFromCookies()
    if (!!(languageCode) && (languageCode !== LanguageCodeEnglish)) {
      UpdateLanguageCode(languageCode)
      this.props.dispatch(commonTriggerRequest(REQ_FETCH_I18N))
    }

    this.props.dispatch(commonTriggerRequest(REQ_FETCH_VIBECHECK_STATISTICS))
    hideLoadingScreen()
  }

  componentWillUnmount() {
    this.props.dispatch(resetCommon());
  }

  render() {
    const resultShortCode = this.props.match.params.resultShortCode
    const isAboutPage = (this.props.location.pathname === "/about")
    const isLevelsPage = (this.props.location.pathname === "/levels")

    const retrieveI18NResult = this.props[REQ_FETCH_I18N]
    const retrievingI18N = retrieveI18NResult === REQUEST_FETCHING

    let historicalVibecheckResult;
    let historicalVibecheckData = {}
    if (!!(resultShortCode)) {
      historicalVibecheckResult = this.props[REQ_FETCH_CACHED_VIBECHECK_RESULT]
      historicalVibecheckData = historicalVibecheckResult.state === REQUEST_SUCCESS && historicalVibecheckResult.result
    }
    const vibecheckCompleted = (this.props[COMMON_VIBE_CHECK_PROGRESS] === 100.0)
    
    return (
      <div>
        {(language !== LanguageCodeEnglish && (retrieveI18NResult.state !== REQUEST_SUCCESS)) ? (
          <AppLoadingPageContainer
            props={this.props}
            appLoadResultState={retrieveI18NResult.state}
            appLoadErrorMessage={loading_i18n[language].loading_site_translations_failed}
            retryButtonText={loading_i18n[language].retry}
            onReloadApp={() => this.props.dispatch(commonTriggerRequest(REQ_FETCH_I18N))}
          />
        ) : (
            isAboutPage ? (
            <AboutPage 
              safeAreaInsets={this.props[COMMON_SAFE_AREA_INSETS]}
              innerHeight={this.props[COMMON_INNER_HEIGHT]}
              showSelectLanguageModal={this.props[COMMON_SHOW_SELECT_LANGUAGE_MODAL]}
              onToggleSelectLanguageModal={show => this.props.dispatch(commonUpdateShowSelectLanguageModal(show))}
            />
          ) : (isLevelsPage ? (
            <LevelsPage
              safeAreaInsets={this.props[COMMON_SAFE_AREA_INSETS]}
              innerHeight={this.props[COMMON_INNER_HEIGHT]}
              showSelectLanguageModal={this.props[COMMON_SHOW_SELECT_LANGUAGE_MODAL]}
              onToggleSelectLanguageModal={show => this.props.dispatch(commonUpdateShowSelectLanguageModal(show))}
            />
          ) : (
            (!!(resultShortCode) && (!(historicalVibecheckData) || (!vibecheckCompleted))) ? (
              <AppLoadingPageContainer
                props={this.props}
                appLoadResultState={historicalVibecheckResult.state}
                appLoadErrorMessage={loading_i18n[language].loading_historical_result_failed}
                retryButtonText={loading_i18n[language].retry}
                onReloadApp={() => this.props.dispatch(commonTriggerRequest(REQ_FETCH_CACHED_VIBECHECK_RESULT))}
              />
            ) : (
              <VibeCheckPage
                vibeCheckSearchText={this.props[COMMON_VIBE_CHECK_SEARCH_TEXT]}
                onUpdateVibeCheckSearchText={text => this.props.dispatch(commonUpdateVibeCheckSearchText(text))}
                onTriggerRequest={reqType => this.props.dispatch(commonTriggerRequest(reqType))}
                onUpdateShowDescription={show => this.props.dispatch(commonUpdateShowDescription(show))}
                onUpdateShowTranscendence={show => this.props.dispatch(commonUpdateShowTranscendence(show))}
                onUpdateBottomSuccessMessage={message => this.props.dispatch(commonUpdateBottomSuccessMessage(message))}
                onUpdateSelectedTrackId={trackId => this.props.dispatch(commonUpdateSelectedTrackId(trackId))}
                onToggleNervousSystemInfoModal={show => this.props.dispatch(commonUpdateShowNervousSystemInfoModal(show))}
                onToggleSelectLanguageModal={show => this.props.dispatch(commonUpdateShowSelectLanguageModal(show))}
                onToggleEmailSubscriptionModal={show => this.props.dispatch(commonUpdateShowEmailSubscriptionModal(show))}
                onToggleErrorMessage={show => this.props.dispatch(commonUpdateRequestState(REQ_RUN_VIBECHECK, (show ? REQUEST_ERROR : REQUEST_UNSTARTED), (show ? {"error_code": "spotify_link_help_message"} : {})))}
                vibeCheckSearchResult={this.props[REQ_FETCH_TRACK_SEARCH_RESULTS]}
                vibeCheckResult={this.props[REQ_RUN_VIBECHECK]}
                vibeCheckProgress={this.props[COMMON_VIBE_CHECK_PROGRESS]}
                statisticsLoading={this.props[REQ_FETCH_VIBECHECK_STATISTICS].state === REQUEST_FETCHING}
                footerStatistics={this.props[COMMON_FOOTER_STATISTICS]}
                showRefreshStatisticsCountdown={this.props[COMMON_SHOW_REFRESH_STATISTICS_COUNTDOWN]}
                refreshStatisticsCountdown={this.props[COMMON_REFRESH_STATISTICS_COUNTDOWN]}
                fetchSpotifyMetadataResult={this.props[REQ_FETCH_SPOTIFY_TRACK_METADATA]}
                analyzeNervousSystemStatesResult={this.props[REQ_ANALYZE_TRACK_VIBRATION]}
                safeAreaInsets={this.props[COMMON_SAFE_AREA_INSETS]}
                innerHeight={this.props[COMMON_INNER_HEIGHT]}
                innerWidth={this.props[COMMON_INNER_WIDTH]}
                showDescription={this.props[COMMON_SHOW_DESCRIPTION]}
                showTranscendence={this.props[COMMON_SHOW_TRANSCENDENCE]}
                showBottomSuccessMessage={this.props[COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE]}
                showNervousSystemInfoModal={this.props[COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL]}
                showSelectLanguageModal={this.props[COMMON_SHOW_SELECT_LANGUAGE_MODAL]}
                showEmailSubscriptionModal={this.props[COMMON_SHOW_EMAIL_SUBSCRIPTION_MODAL]}
                bottomSuccessMessage={this.props[COMMON_BOTTOM_SUCCESS_MESSAGE]}
                props={this.props}
              />
            )))
          )
        }
      </div>
    )
  }
}

MainAppContainer.propTypes = {
  [REQ_RUN_VIBECHECK]: PropTypes.object.isRequired,
  [COMMON_VIBE_CHECK_SEARCH_TEXT]: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({...state.common, location: state.router.location});

export default connect(mapStateToProps)(MainAppContainer);
