import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  commonTriggerRequest
} from '../actions/common';
import AppLoadingPage from '../components/AppLoadingPage';

import {
  REQ_FETCH_APP_CONFIG,
} from '../constants/requestTypes'

import {
  COMMON_INNER_HEIGHT,
} from '../constants/commonState'

import {
  hideLoadingScreen,
} from '../utils'

class AppLoadingPageContainer extends Component {

  componentDidMount() {
    hideLoadingScreen()
  }

  render() {
    return (
      <AppLoadingPage
        height={this.props.innerHeight}
        appLoadResultState={this.props.appLoadResultState}
        appLoadErrorMessage={this.props.appLoadErrorMessage}
        retryButtonText={this.props.retryButtonText}
        onReloadApp={() => this.props.onReloadApp()}
      />
    );
  }
}

AppLoadingPageContainer.propTypes = {
  appLoadResultState: PropTypes.string.isRequired,
  innerHeight: PropTypes.number.isRequired,
  onReloadApp: PropTypes.func.isRequired,
  retryButtonText: PropTypes.string.isRequired,
  appLoadErrorMessage: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  innerHeight: state.common[COMMON_INNER_HEIGHT],
});

export default connect(mapStateToProps)(AppLoadingPageContainer);
