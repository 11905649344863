import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import {history} from '../store';

import { polyfill } from "seamless-scroll-polyfill";

import MainAppContainer from '../containers/MainAppContainer';

import { 
  initializeGA,
  pageViewGA,
  subdomainHosts,
} from '../utils';

import {
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'


require('../style.css');

polyfill();

const mapStateToProps = state => ({state});

class App extends React.Component {

  componentWillMount() {
    initializeGA();
    pageViewGA();
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={MainAppContainer} />
          <Route exact path="/about" component={MainAppContainer} />
          <Route exact path="/levels" component={MainAppContainer} />
          <Route exact path="/s/:resultShortCode" component={MainAppContainer} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps)(App);
