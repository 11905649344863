import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range, map } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import {
  nervousSystemStates,
} from '../constants/nervousSystemStates'

import {
  REQ_RUN_VIBECHECK,
  REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE,
} from '../constants/requestTypes'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../constants/requestStates';

import {
  i18n,
  baseDomain,
  language,
  copyToClipboard,
  recordVibeCheckFunnelEventGA,
} from '../utils'

import {
  GA_LABEL_CLICKED_SELECT_LANGUAGE
} from '../constants/gaLabels'

import SelectLanguageModal from './modals/SelectLanguageModal'

import {
  ChooseLanguageSvg,
} from '../constants/svgs'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

class LevelsPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        headerColor: "transparent",
        dropShadowColor: "rgba(0, 0, 0, 0.15)",
        show: false,
     }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.4, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${Math.max((alpha/3.0) + 0.15, 0.15)})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "rgba(0, 0, 0, 0.15)"})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      showSelectLanguageModal,
      onToggleSelectLanguageModal,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor} = this.state

    const textShadow = range(4).map(() => (`0px 0px 1.5px ${dropShadowColor}`)).join(",")
    const baseSvgDropShadow = `0px 0px 1px ${!!(dropShadowColor) ? dropShadowColor : "#353F6A"}`
    const svgDropShadow = range(2).map(() => (`drop-shadow(${baseSvgDropShadow})`)).join(" ")
   
    return (
      <CSSTransition
        in={true}
        timeout={300}
        classNames={"fade-in-300"}
        unmountOnExit
        onExited={() => {}}
      >
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, backgroundColor: headerColor, display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 + safeAreaInsets.top, backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", zIndex: 1000}}>
            <div>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                padding={"0px"}
                marginTop={0}
                color={"white"}
                fontFamily={"Varela Round"}
                text={baseDomain}
                showProgressionBar={false}
                dropShadowColor={dropShadowColor}
                dropShadowMultiplier={5}
                onClick={() => window.location = "/"}
              />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{textAlign: "center", marginTop: "min(10px, 2.5vw)"}}>
                <div
                  style={{height: "min(6vw, 30px)", width: "min(6vw, 30px)", cursor: "pointer", overflow: "visible", filter: svgDropShadow}}
                  onClick={() => {
                    recordVibeCheckFunnelEventGA(GA_LABEL_CLICKED_SELECT_LANGUAGE)
                    onToggleSelectLanguageModal(true)
                  }}
                >
                  {ChooseLanguageSvg}
                </div>
                <div style={{color: "#fff", textShadow, height: "min(10px, 2.5vw)", fontSize: "min(10px, 2.5vw)"}}>
                  {language.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
          <div 
            className={"disable-scrollbars"}
            style={containerStyle}
            ref={elem => this.scrollingContainerRef = elem}
          >
            <div style={{textAlign: "left", height: "100%", width: "100vw"}}>
              <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: "15vh"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{marginTop: 15, fontSize: "min(7vw, 35px)", textAlign: "left", fontFamily: "Nunito", fontWeight: 600}}>
                    <div style={{display: "inline-block"}}>
                      {i18n.the_nervous_system_states}
                    </div>
                  </div>
                  {map(nervousSystemStates, selectedLevel => {
                    const level = i18n.nervous_system_states[selectedLevel.name]
                    return (
                      <div key={level.display_name}>
                        <div style={{display: "inline-block", marginTop: 50}}>
                          <div
                            style={{display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 8, padding: "10px 20px", backgroundColor: "rgba(255, 255, 255, 0.3)", boxShadow: "0px 0px 1.5px 1px #fff"}}
                          >
                            <div style={{fontSize: "min(8vw, 40px)", textAlign: "left", fontFamily: "Nunito", fontWeight: 600}}>
                              <div style={{display: "inline-block", color: selectedLevel.color}}>
                                {`${level.display_name}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{marginTop: 20, fontSize: "min(4.5vw, 25px)", lineHeight: "min(6vw, 32px)", fontFamily: "Varela Round", color: "#ddd"}}>
                          <div style={{display: "inline-block", textAlign: "left", maxWidth: "100%"}}>
                            {level.short_description}
                          </div>
                        </div>

                        <div style={{marginTop: 20, fontSize: "min(7vw, 35px)", textAlign: "left", fontFamily: "Nunito", fontWeight: 600}}>
                          <div style={{display: "inline-block"}}>
                            {i18n.benefits}
                          </div>
                        </div>
                        <div style={{marginTop: 10, fontSize: "min(4.5vw, 25px)", lineHeight: "min(6vw, 32px)", fontFamily: "Varela Round", color: "#ddd"}}>
                          <div style={{display: "inline-block", textAlign: "left", maxWidth: "100%"}}>
                            {level.benefits.split('\n').map(str => <p style={{margin: "10px 0px"}}>{str}</p>)}
                          </div>
                        </div>
                        {(!!(level.drawbacks)) && (
                          <div>
                            <div style={{marginTop: 20, fontSize: "min(7vw, 35px)", textAlign: "left", fontFamily: "Nunito", fontWeight: 600}}>
                              <div style={{display: "inline-block"}}>
                                {i18n.drawbacks}
                              </div>
                            </div>
                            <div style={{marginTop: 10, fontSize: "min(4.5vw, 25px)", lineHeight: "min(6vw, 32px)", fontFamily: "Varela Round", color: "#ddd"}}>
                              <div style={{display: "inline-block", textAlign: "left", maxWidth: "100%"}}>
                                {level.drawbacks.split('\n').map(str => <p style={{margin: str.startsWith("•") ? "10px 0px 10px 10%" : "10px 0px"}}>{str}</p>)}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                  <button 
                    style={{border: "none", padding: "min(2vh, 15px) 0px", margin: 0, marginTop: 30, backgroundColor: "transparent", borderRadius: 10, overflow: "hidden", fontFamily: "Nunito", fontWeight: 600, fontSize: "min(3vh, 25px)", color: "white", display: "flex", alignItems: "center", justifyContent: "left"}}
                    onClick={() => window.location = "/"}>
                    <div style={{marginRight: 5, display: "inline-block", maxHeight: "min(3vh, 25px)", maxWidth: "min(3vh, 25px)", transform: "rotate(180deg)"}}>
                      <svg className="authentique-svg" height='100px' width='100px' fill="#fff" style={{color: "#fff"}} version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style={{enableBackground:"new 0 0 100 100"}}><path d="M95.4,45L66.1,15.7c-2.7-2.7-7.2-2.7-9.9,0c-2.7,2.7-2.7,7.2,0,9.9L73.5,43h-64c-3.9,0-7,3.1-7,7s3.1,7,7,7h64L56.2,74.3    c-2.7,2.7-2.7,7.2,0,9.9c1.4,1.4,3.2,2.1,5,2.1s3.6-0.7,5-2.1L95.4,55c1.3-1.3,2.1-3.1,2.1-5C97.5,48.1,96.8,46.3,95.4,45z"></path></svg>
                    </div>
                    <div style={{display: "inline-block", color: "#fff"}}>
                      {i18n.back_to_home}
                    </div>
                  </button>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw"}}>
                <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, color: "#ddd", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    {"© 2023 NEUROFIT"}
                  </div>
                  <div style={{display: "inline-block"}}>
                    <a href={"https://legal.neurofit.app/#privacy"} target="_blank" rel="noreferrer noopener" style={{color: "#ddd"}}>
                      {i18n.privacy}
                    </a>
                    <div style={{display: "inline-block", margin: "0px 5px", fontSize: "min(3vw, 15px)",}}>
                      {" • "}
                    </div>
                    <a href={"https://legal.neurofit.app/#terms"} target="_blank" rel="noreferrer noopener" style={{color: "#ddd"}}>
                      {i18n.terms}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SelectLanguageModal
            show={showSelectLanguageModal}
            innerHeight={innerHeight}
            onHide={() => onToggleSelectLanguageModal(false)}
          />
        </div>
      </CSSTransition>
    )
  }
}

export default LevelsPage;