import React from 'react';
import queryString from 'query-string'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {history} from './store';
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

import loaded_i18n from './constants/i18n.json';
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)


export const HEADER_HEIGHT_PIXELS = 70
export const FOOTER_HEIGHT_PIXELS = 70
export const MAX_TEST_RESPONSE_BUFFER_SIZE = 5
export const MS_PER_HOUR = 1000 * 60 * 60

export const isProduction = (process.env.NODE_ENV === 'production')

// Analytics Utils
export const initializeGA = () => {
  if (isProduction) {

  }
}

export const pageViewGA = () => {
  if (isProduction) {
  }
}

export const recordEventGA = (eventType, eventName, amplitudeContext=undefined) => {
  if (isProduction) {
    // ReactGA.event({
    //   category: eventType,
    //   action: eventName,
    //   label: "Analytics Event",
    //   value: 1
    // });
    // // Log all events to Amplitude as well for better funnel analysis.
    // window.amplitude.getInstance().logEvent(eventType + ": " + eventName, amplitudeContext)
  }
}

export const recordStoryReadEvent = (storyKey) => {
  return recordEventGA("Story Read", storyKey)
}

export const recordLinkClickGA = (eventName) => {
  return recordEventGA("Link Click", eventName);
}

export const recordVibeCheckFunnelEventGA = (eventName, amplitudeContext=undefined) => {
  return recordEventGA("Vibe Check Funnel", eventName, amplitudeContext);
}

export const recordSessionEventGA = (sessionType) => {
  return recordEventGA("Session", sessionType)
}

export const configureAmplitudeUser = ({isSubscribed=false, page=undefined, language='en'}) => {
  // let identify = new window.amplitude.Identify()
  //   .set('is_subscribed', isSubscribed)
  //   .set('page', page)
  //   .set('language', language)
  // window.amplitude.getInstance().identify(identify)

}

// User Agent Utils
export const getUserAgent = () => {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export const isIOSDevice = () => {
  return (/iPad|iPhone|iPod/.test(getUserAgent()) && !window.MSStream)
}

export const isIOSSafari = () => {
  if (!isIOSDevice()) {
    return false
  }
  const ua = window.navigator.userAgent;
  return (!!ua.match(/WebKit/i) && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i))
}

export const isAndroidDevice = () => {
  return (/android/i.test(getUserAgent()))
}

export const runningAsApp = () => {
  if (isIOSDevice()) {
    return (window.navigator.standalone === true)
  } else {
    // Android
    return window.matchMedia('(display-mode: standalone)').matches
  }
}

export const isIOSStandalone = () => {
  return isIOSDevice() && (window.navigator.standalone === true)
}

export const getInstagramLinkForDevice = (username) => {
  if (isAndroidDevice()) {
    return "https://www.instagram.com/_u/" + username + "/"
  } else {
    return "https://www.instagram.com/" + username + "/"
  }
}

export const getAuthentiqueInstagramLinkForDevice = () => getInstagramLinkForDevice("authentique_inc")

export const getStoryShortCodeFromAppUrl = url => {
  const shortCodeDeeplinkPrefix = "?id="
  const urlComponents = url.split(shortCodeDeeplinkPrefix)
  if (urlComponents.length === 2) {
    return urlComponents.pop()
  }
  return ""
}

export const getShouldSkipStoryIntroFromAppUrl = url => {
  const skipStoryIntroPrefix = "?h="
  const urlComponents = url.split(skipStoryIntroPrefix)
  if (urlComponents.length === 2) {
    return urlComponents.pop() === "1"
  }
  return false
}

const ACTION_LOGIN = 'login'
const PARAM_LOGIN_TOKEN = 'token'
const ACTION_PURCHASE_SUCCESS = 'purchase_success'
const PARAM_STRIPE_CHECKOUT_SESSION_ID = 'stripe_checkout_session_id'
const PARAM_REFERRAL = 'r'
const PARAM_DISCOUNT_CODE = 'c'
const PARAM_STORY_SHORT_CODE = 'id'
const PARAM_STRIPE_SUBSCRIPTION_CREATED = "subscription_created"
const locationHasAction = (queryParameters, action) => {
  return !!(queryParameters.action === action)
}

const getFromLocation = (queryParameters, key) => {
  return queryParameters[key]
}

export const getLoginParametersFromLocation = (location) => {
  const queryParameters = queryString.parse(location.search)
  return {
    isLoginLink: locationHasAction(queryParameters, ACTION_LOGIN),
    loginToken: getFromLocation(queryParameters, PARAM_LOGIN_TOKEN)
  }
}

export const getShouldHideIntroVideoFromLocation = (location) => {
  const queryParameters = queryString.parse(location.search)
  return (queryParameters.h === "1")
}

export const getReferralCodeParametersFromLocation = location => {
  const queryParameters = queryString.parse(location.search)
  const referral_code = getFromLocation(queryParameters, PARAM_REFERRAL)
  return {
    has_referral_code: !!(referral_code),
    referral_code,
  }
}

export const getStripeCheckoutParametersFromLocation = location => {
  const queryParameters = queryString.parse(location.search)
  return {
    isSuccessfulPurchase: locationHasAction(queryParameters, ACTION_PURCHASE_SUCCESS),
    checkoutSessionId: getFromLocation(queryParameters, PARAM_STRIPE_CHECKOUT_SESSION_ID),
  }
}

export const getStripeSubscriptionCreatedFromLocation = location => {
  const queryParameters = queryString.parse(location.search)
  return {
    subscription_created: (getFromLocation(queryParameters, PARAM_STRIPE_SUBSCRIPTION_CREATED) === "1"),
  }
}

export const getDiscountCodeFromLocation = (location) => {
  const queryParameters = queryString.parse(location.search)
  return getFromLocation(queryParameters, PARAM_DISCOUNT_CODE)
}

export const getStoryShortCodeFromLocation = (location) => {
  const queryParameters = queryString.parse(location.search)
  return getFromLocation(queryParameters, PARAM_STORY_SHORT_CODE)
}

export const baseDomain = "checkthevibe.app"
export const baseAppHost = isProduction ? baseDomain : "localhost:4105"

export const subdomainHosts = {
  baseAppHost,
}



const httpPrefix = isProduction ? "https://" : "http://"

export const baseAppUrl = httpPrefix + baseAppHost

export const subdomainUrls = {
  baseAppUrl,
}

export const CheckthevibeSpotifyAccountUrl = "https://open.spotify.com/user/zclyyomiuqkxzoamxjfyxn9r9"

export const joinWithAnd = arr => {
  const temp = arr.join(', ')
  if (arr.length > 2) {
    return temp.replace(/,(?!.*,)/gmi, ', and');
  } else {
    return temp.replace(/,(?!.*,)/gmi, ' and');
  }
}

export const copyToClipboard = (function(){
  let textToCopy;
  let clearSelection = false;
  let didCopy = false;
  document.addEventListener('copy', e => {
    if (textToCopy !== undefined) {
      try {
        e.clipboardData.setData('text/plain', textToCopy);
        e.preventDefault();
        didCopy = true;
      } finally {
        textToCopy = undefined;
      }
    }
  });
  return function(text) {
    textToCopy = text;
    if (!document.queryCommandEnabled('copy')) {
      // See: https://bugs.webkit.org/show_bug.cgi?id=156529
      const sel = document.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.body);
      sel.addRange(range);
      clearSelection = true;

    }
    didCopy = false;
    document.execCommand('copy');
    if (clearSelection) {
      clearSelection = false;
      document.getSelection().removeAllRanges();
    }
    return didCopy;
  };
})();

export const toggleBodyScroll = (enable) => {
    if (enable) {
      document.body.classList.remove("noscroll")
    } else {
      document.body.classList.add("noscroll")
    }
}


// Default to this in case initial page load (before i18n is fetched) fails.
export let i18n = loaded_i18n["en"]
export let language = "en"
export const LanguageCodeEnglish = "en"


export const languageNames = [
  {
    "language_code": "en",
    "display_name": "english"
  },
  {
    "language_code": "pt",
    "display_name": "português"
  },
  {
    "language_code": "de",
    "display_name": "deutsch"
  },
  {
    "language_code": "es",
    "display_name": "español"
  },
  {
    "language_code": "id",
    "display_name": "bahasa Indonesia"
  },
  {
    "language_code": "it",
    "display_name": "italiano"
  },
  {
    "language_code": "fr",
    "display_name": "français"
  },
  {
    "language_code": "ar",
    "display_name": "عربي"
  },
  {
    "language_code": "ru",
    "display_name": "русский"
  },
  {
    "language_code": "hi",
    "display_name": "हिंदी"
  },
  {
    "language_code": "hr",
    "display_name": "hrvatski"
  },
  {
    "language_code": "el",
    "display_name": "ελληνικά"
  },
]


export const loading_i18n = {
  "en": {
    "loading_historical_result_failed": "Error: Couldn't load historical vibe check result.",
    "loading_site_translations_failed": "Error: Loading site translations failed.",
    "retry": "Retry"
  },
  "pt": {
    "loading_historical_result_failed": "Erro: Não foi possível carregar resultado histórico do Vibe Check.",
    "loading_site_translations_failed": "Erro: Carregamento de traduções do site falhou.",
    "retry": "Tente novamente"
  },
  "de": {
    "loading_historical_result_failed": "Fehler: konnte keine historische Vibe-Überprüfungsresultate laden.",
    "loading_site_translations_failed": "Fehler: Laden von Webseitenübersetzungen gescheitert.",
    "retry": "Wiederholen"
  },
  "es": {
    "loading_historical_result_failed": "Error: No se pudo cargar el resultado histórico de la comprobación de la vibra.",
    "loading_site_translations_failed": "Error: No se pudo cargar los traducciones de la página.",
    "retry": "Reintentar"
  },
  "id": {
    "loading_historical_result_failed": "Error: Tidak dapat mengekstrak hasil historis Vibe Check.",
    "loading_site_translations_failed": "Error: Membaca site pengganti gagal.",
    "retry": "Coba lagi"
  },
  "it": {
    "loading_historical_result_failed": "Error: non è stato possibile caricare il risultato storico del controllo vibrazioni",
    "loading_site_translations_failed": "Error: non è stato possibile caricare le traduzioni del sito",
    "retry": "Riprova"
  },
  "fr": {
    "loading_historical_result_failed": "Erreur: Impossible de charger les résultats historiques du test de vibe.",
    "loading_site_translations_failed": "Erreur: Impossible de charger les traductions du site.",
    "retry": "Réessayez"
  },
  "ar": {
    "loading_historical_result_failed": "إخفاء تحويل النتائج التاريخية إلي غير محفوظة",
    "loading_site_translations_failed": "إخفاء تحويل الموقع المترجم إلي غير محفوظة",
    "retry": "تمرير"
  },
  "ru": {
    "loading_historical_result_failed": "Ошибка: Не удалось загрузить результат проверки вибрации последнего времени.",
    "loading_site_translations_failed": "Ошибка: Загрузка транслитерации сайта не удалось.",
    "retry": "Попробуйте снова"
  },
  "hi": {
    "loading_historical_result_failed": "त्रुटि: ऐतिहासिक वाइब जांच परिणाम लोड नहीं किया जा सका।",
    "loading_site_translations_failed": "त्रुटि: साइट अनुवाद लोड करना विफल रहा।",
    "retry": "पुन: प्रयास करें"
  },
  "hr": {
    "loading_historical_result_failed": "Pogreška: Nije moguće učitati povijesni rezultat provjere vibracije.",
    "loading_site_translations_failed": "Pogreška: učitavanje prijevoda web lokacije nije uspjelo.",
    "retry": "Pokušaj ponovo"
  },
  "el": {
    "loading_historical_result_failed": "Σφάλμα: Δεν ήταν δυνατή η φόρτωση του αποτελέσματος ελέγχου ιστορικού vibe.",
    "loading_site_translations_failed": "Σφάλμα: Αποτυχία φόρτωσης μεταφράσεων ιστότοπου.",
    "retry": "Ξαναδοκίμασε"
  },
}

export const SetGlobalI18N = (updatedI18N, updatedLanguage) => {
  i18n = updatedI18N
  language = updatedLanguage
}

export const UpdateLanguageCode = lang => {
  language = lang
}

export const getLocalizedErrorMessageFromRequestResult = (req) => {
  return !!(req.error) ? i18n[req.error.error_code] : ""
}




// Localized Nunber Formatting
export function formatStatistic(statistic, isInt=false, isPercentage=false, decimals=1) {
  if (statistic >= 1000000.0) {
    return formatSeparator((statistic / 1000000.0).toFixed(1)) + getMillionsAbbrevForLanguage()
  } else if (statistic >= 1000.0) {
    return formatSeparator((statistic / 1000.0).toFixed(decimals || 1)) + getThousandsAbbrevForLanguage()
  } else if (statistic >= 1.0) {
    return !!(isInt) ? statistic.toFixed(0) : (isPercentage ? formatSeparator(statistic.toFixed(0), isPercentage) : formatSeparator(statistic.toFixed(1)))
  } else {
    return !!(isInt) ? statistic : formatSeparator(statistic.toFixed(1), isPercentage)
  }
}

function getMillionsAbbrevForLanguage() {
  const abbrevMap = {
    "en": "M",
    "de": "m",
    "es": "M",
    "fr": " M",
    "id": " jt",
    "it": "Mln",
    "pt": " mi",
    "ru": " млн",
    "ar": " مليو",
  }
  return abbrevMap[language] || "M"
}

function getThousandsAbbrevForLanguage() {
  const abbrevMap = {
    "en": "K",
    "de": "k",
    "es": "K",
    "fr": " k",
    "id": " rb",
    "it": "mila",
    "pt": " mil",
    "ru": " тыс.",
    "ar": " ألف",
  }
  return abbrevMap[language] || "K"
}

function getPercentageForLanguage() {
  const abbrevMap = {
    "en": "%",
    "de": " %",
    "es": " %",
    "fr": " %",
    "id": "%",
    "it": "%",
    "pt": "%",
    "ru": " %",
    "ar": "%",
  }
  return abbrevMap[language] || "%"
}

function formatSeparator(numberString, percentage=false) {
  const separatorMap = {
    "en": ".",
    "de": ",",
    "es": ",",
    "fr": ",",
    "id": ",",
    "it": ",",
    "pt": ",",
    "ru": ",",
    "ar": ".",
  }
  let result = numberString.replace(".", separatorMap[language] || ".")
  return (!(percentage) ? result : result + getPercentageForLanguage())
}

export const formatContentDuration = (durationSeconds) => {
  if (durationSeconds < 0) {
    return "0:00"
  }
  const seconds = (durationSeconds % 60)
  return Math.floor(durationSeconds / 60) + ":" + ((seconds < 10) ? "0" : "") + seconds
}

export const isPower = (x, n) => {
  if (n < 2) {
    return false
  }
  if (x < n) {
    return false
  }
  if (x === n) {
    return true
  }

  let curr = n;
  while (curr < x) {
    curr *= n
    if (curr === x) {
      return true
    }
  }
  return false
}

export const hideLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  if (!!(loadingScreenElem)) {
    loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const removeHttpsPrefixFromLink = link => !!(link) ? link.replace(/^https?:\/\//,'') : ""

export const setWindowUrlToBase = () => {
  history.push("/");
}

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const currentTime = () => dayjs()
export const time = (s, format) => dayjs(s, format)
export const get7AMLocalTimeWithTimezone = () => dayjs().tz(dayjs.tz.guess()).format('[7:00 am] z')
export const getTimeZoneOffset = () => ({
  name: dayjs.tz.guess(),
  offset: dayjs().tz(dayjs.tz.guess()).format('Z')
})

export const formatDurationSeconds = durationSeconds => {
  return dayjs.duration({seconds: durationSeconds}).format("m:ss")
}

export const formatReadableUnixTimestamp = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("dddd, MMMM D, YYYY")
}

export const recommendationsFileIsActive = timestampSeconds => {
  console.log(currentTime().diff(dayjs.unix(timestampSeconds), 'day', true))
  return currentTime().diff(dayjs.unix(timestampSeconds), 'day', true) <= 1.0
}

export const getFormattedDateDaysFromNow = (n) => {
  return dayjs().add(n, 'day').format("MMMM D, YYYY")
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

/////////////
// Cookies //
/////////////
const CSRF_TOKEN_KEY = 'csrf_token'
const LANGUAGE_CODE_KEY = '_vibecheck_language_code'
const PRIORITIZED_LANGUAGE_CODE_KEY = '_vibecheck_language_code_prioritized'
const BETA_TESTING_AUTHORIZATION = 'beta_testing_authorization'
export const INVITE_CODE_LOCAL_STORAGE_KEY = "alma__local_storage_b2b_invite_code"
const COOKIE_MISSING = "_missing"
const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return COOKIE_MISSING;
}

export const getCsrfTokenFromCookies = () => getCookie(CSRF_TOKEN_KEY)
export const getCsrfTokenCookieIsPresent = () => {
  const c = getCookie(CSRF_TOKEN_KEY)
  return (c !== COOKIE_MISSING) && !!(c)
}

export const setPrioritizedLanguageCodeCookie = languageCode => {
  document.cookie = `${PRIORITIZED_LANGUAGE_CODE_KEY}=${languageCode}; expires=Sun, 1 Jan 3000 00:00:00 UTC; path=/;`
}

export const getLanguageCodeFromCookies = () => {
  const prioritizedResult = getCookie(PRIORITIZED_LANGUAGE_CODE_KEY)
  if (prioritizedResult !== COOKIE_MISSING && !!(prioritizedResult)) {
    return prioritizedResult
  }
  const result = getCookie(LANGUAGE_CODE_KEY)
  return (result === COOKIE_MISSING || !(result)) ? "" : result
}


export const SpotifySongUrlPrefix = "https://open.spotify.com/track/"

export const validateVibeCheckUrl = url => {
  return url.startsWith(SpotifySongUrlPrefix)
}
