import {range, filter} from 'lodash-es'

export const nervousSystemStates = [
  {
    min: 0,
    max_exclusive: 60,
    name: "stillness",
    display_name: "Stillness",
    title_name: "stillness",
    color: "#93E29A",
    color_pastel: "#93E29A"
  },
  {
    min: 60,
    max_exclusive: 120,
    name: "parasympathetic",
    display_name: "Ventral Vagal",
    title_name: "ventral",
    color: "#FFD634",
    color_pastel: "#FFD634"
  },
  {
    min: 120,
    max_exclusive: 180,
    name: "play",
    title_name: "play",
    display_name: "Play",
    color: "#FFAA32",
    color_pastel: "#FFAA32"
  },
  {
    min: 180,
    max_exclusive: 240,
    name: "sympathetic",
    title_name: "sympathetic",
    display_name: "Sympathetic",
    color: "#FF7CBC",
    color_pastel: "#FF7CBC"
  },
  {
    min: 240,
    max_exclusive: 300,
    name: "overwhelm",
    title_name: "overwhelm",
    display_name: "Overwhelm",
    color: "#9EA0DA",
    color_pastel: "#9EA0DA"
  },
  {
    min: 300,
    max_exclusive: 360,
    name: "dorsal_vagal",
    title_name: "dorsal",
    display_name: "Dorsal Vagal",
    color: "#1ACFF2",
    color_pastel: "#1ACFF2"
  }
]

export const getMetaForNervousSystemState = stateName => nervousSystemStates.filter(state => state.name === stateName)[0]