import React from 'react'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import LoadingLogo from './LoadingLogo'

import {
  formatContentDuration,
} from '../../utils'

import millify from "millify";

import {flag} from 'country-emoji'

const tagsStyle = {display: "flex", justifyContent: "left", alignItems: "center", flexWrap: "wrap", flexDirection: "row"}
const tagStyle = {fontFamily: "Varela Round", fontSize: 12, padding: "1px 1px", color: "#eeeeee", opacity: 0.8, display: "inline-block", margin: "4px 4px 4px 0px"}
const tagSeparatorStyle = {color: "#cccccc", opacity: 1.0}
const tagCategoryNameStyle = {opacity: 0.8, height: 22, width: 22, color: "#cccccc", margin: 5}

const contentTitleTextStyle = {
    // maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: "calc(100% - 15px)",
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: "min(4.5vw, 20px)"
  };

const SearchResultArtistTrackDisplay = ({
  // State
  displayArtImageUrl,
  trackTitle,
  artistName,
  dropShadowColor,
  onClick

}) => {

  return (
    <div
      style={{minWidth: "min(75%, 300px)", cursor: "pointer", height: "min(100%, 450px)", padding: "0px"}}
      onClick={() => onClick()}
    >
      <div
        style={{color: "#444", fontFamily: "Varela Round", fontWeight: 800, border: "none", fontSize: 14, width: "100%", height: "100%", borderRadius: 10, backgroundColor: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", boxShadow: "0px 0px 2px 1.5px white", overflow: "visible", display: "block", padding: 0}}
      >
        <div style={{padding: "min(2vw, 10px)", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", width: "100%", alignItems: "center"}}>
            <div style={{height: "min(12vw, 50px)", width: "min(12vw, 50px)", minWidth: "min(12vw, 50px)", backgroundImage: `url(${displayArtImageUrl})`, backgroundColor: "#ccc", borderRadius: 8, backgroundPosition: "center", backgroundSize: "cover", boxShadow: "0px 0px 1.5px 0.5px #ffffff"}}>
            </div>
            <div style={{marginLeft: 15, width: "calc(100% - 65px)"}}>
              <div style={{...contentTitleTextStyle, fontWeight: 800,}}>
                {trackTitle}
              </div>
              <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                <div style={{display: "inline-block", height: "min(5vw, 20px)", lineHeight: "min(5vw, 20px)", fontSize: "min(3.5vw, 16px)", marginTop: 3, width: "calc(100% - 30px)", fontWeight: 800, color: "#444", overflow: "hidden", "whiteSpace": "nowrap", textOverflow: "ellipsis"}}>
                  {artistName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchResultArtistTrackDisplay

