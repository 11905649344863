import React from 'react'

import {
  AddCircleSvg,
  RemoveCircleSvg,
} from '../../constants/svgs'

class FaqSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAnswer: false
    }
  }
  render() {
    const {
      headerText,
      expandedText,
      marginTop,
      marginBottom,
      color
    } = this.props
    const {showAnswer} = this.state
    return (
      <button onClick={() => this.setState({showAnswer: !showAnswer})} style={{marginTop, marginBottom, backgroundColor: "transparent", fontFamily: "Nunito", border: "none", width: "100%", padding: "min(4vw, 16px)"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{display: "inline-block", height: "min(4.5vw, 18px)", width: "min(4.5vw, 18px)"}}>
            {showAnswer ? (
              <RemoveCircleSvg size={"min(4.5vw, 18px)"} color={color} />
            ) : (
              <AddCircleSvg  size={"min(4.5vw, 18px)"} color={color} />
            )}
          </div>
          <span style={{color: color || "#444", fontSize: "min(4.5vw, 18px)", marginLeft: 15, textAlign: "left", fontFamily: "Nunito", fontWeight: 800}}>
            {headerText}
          </span>
        </div>
        <div style={{display: showAnswer ? "block" : "none", color: "#444", fontSize: "min(4vw, 18px)", marginTop: showAnswer ? "min(4vw, 16px)" : "0px", textAlign: "left", fontFamily: "Nunito"}}>
          {expandedText}
        </div>
      </button>
    );
  }
}

export default FaqSection;