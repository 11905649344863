export const COMMON_APP_NAME = 'appName'
export const COMMON_VIBE_CHECK_SEARCH_TEXT = 'vibeCheckSearchText'
export const COMMON_INNER_HEIGHT = 'innerHeight'
export const COMMON_INNER_WIDTH = 'innerWidth'
export const COMMON_SAFE_AREA_INSETS = 'safeAreaInsets'
export const COMMON_VIBE_CHECK_PROGRESS = 'vibeCheckProgress'
export const COMMON_MAX_VIBE_CHECK_PROGRESS = 'maxVibeCheckProgress'
export const COMMON_SHOW_DESCRIPTION = 'showDescription'
export const COMMON_SHOW_TRANSCENDENCE = 'showTranscendence'
export const COMMON_SHOW_BOTTOM_SUCCESS_MESSAGE = 'showBottomSuccessMessage'
export const COMMON_BOTTOM_SUCCESS_MESSAGE = 'bottomSuccessMessage'
export const COMMON_CACHED_RESULT_SHORT_CODE = 'cachedResultShortCode'
export const COMMON_FOOTER_STATISTICS = 'footerStatistics'
export const COMMON_PAGE_IS_VISIBLE = 'pageIsVisible'
export const COMMON_SHOW_REFRESH_STATISTICS_COUNTDOWN = 'showRefreshStatisticsCountdown'
export const COMMON_REFRESH_STATISTICS_COUNTDOWN = 'refreshStatisticsCountdown'
export const COMMON_SHOW_NERVOUS_SYSTEM_INFO_MODAL = 'showNervousSystemInfoModal'
export const COMMON_SHOW_SELECT_LANGUAGE_MODAL = 'showSelectLanguageModal'
export const COMMON_SHOW_EMAIL_SUBSCRIPTION_MODAL = 'showEmailSubscriptionModal'
export const COMMON_SELECTED_TRACK_ID = 'selectedTrackId'