import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import TopErrorMessage from './common/TopErrorMessage'
import ErrorPanel from './common/ErrorPanel'
import NervousSystemEffectsDescription from './common/NervousSystemEffectsDescription'
import ArtistTrackDisplay from './common/ArtistTrackDisplay'
import SearchResultArtistTrackDisplay from './common/SearchResultArtistTrackDisplay'
import BottomSuccessMessage from './common/BottomSuccessMessage'
import SelectLanguageModal from './modals/SelectLanguageModal'
import NervousSystemStateInfoModal from './modals/NervousSystemStateInfoModal'

import PWAPrompt from 'react-ios-pwa-prompt'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import {
  ChooseLanguageSvg,
  NeuroFitCutoutSvg,
} from '../constants/svgs'

import Gradient from '../gradient'

import {
  REFRESH_STATISTICS_COUNTDOWN_SECONDS
} from '../sagas'

import {
  REQ_FETCH_TRACK_SEARCH_RESULTS,
  REQ_RUN_VIBECHECK,
  REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE,
} from '../constants/requestTypes'

import {
  GA_LABEL_CLICKED_HOME_BUTTON,
  GA_LABEL_CLICKED_SHARE_BUTTON,
  GA_LABEL_CLICKED_SHOW_DETAILS_BUTTON,
  GA_LABEL_CLICKED_SHOW_RESULTS_INFO_BUTTON,
  GA_LABEL_LOADED_VIBE_CHECK_PAGE,
  GA_LABEL_FOCUSED_SPOTIFY_SEARCH_BAR,
  GA_LABEL_CLICKED_SELECT_LANGUAGE,
  GA_LABEL_CLICKED_EMAIL_SUBSCRIPTION_BUTTON,
} from '../constants/gaLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../constants/requestStates';

import {
  i18n,
  baseDomain,
  copyToClipboard,
  CheckthevibeSpotifyAccountUrl,
  CheckthevibeTiktokAccountUrl,
  recordLinkClickGA,
  recordVibeCheckFunnelEventGA,
  validateVibeCheckUrl,
  language,
} from '../utils'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

class VibeCheckPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        headerColor: "transparent",
        dropShadowColor: "rgba(0, 0, 0, 0.05)",
        show: false,
     }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, (e.target.scrollTop / 500))
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0 + 0.05})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }
  }


  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
    var gradient = new Gradient();
    gradient.initGradient("#gradient-canvas");
    recordVibeCheckFunnelEventGA(GA_LABEL_LOADED_VIBE_CHECK_PAGE)
    // gradient.play()
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      innerWidth,
      vibeCheckSearchText,
      onUpdateVibeCheckSearchText,
      onUpdateShowDescription,
      onUpdateShowTranscendence,
      vibeCheckResult,
      vibeCheckSearchResult,
      fetchSpotifyMetadataResult,
      analyzeNervousSystemStatesResult,
      vibeCheckProgress,
      showTranscendence,
      showDescription,
      bottomSuccessMessage,
      showBottomSuccessMessage,
      statisticsLoading,
      footerStatistics,
      showRefreshStatisticsCountdown,
      refreshStatisticsCountdown,
      showSelectLanguageModal,
      showNervousSystemInfoModal,

      onUpdateFormField,
      onUpdateRequestState,
      onTriggerRequest,
      onUpdateBottomSuccessMessage,
      onUpdateSelectedTrackId,
      onToggleSelectLanguageModal,
      onToggleNervousSystemInfoModal,
      onToggleErrorMessage
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor} = this.state

    const dropShadow = range(4).map(() => (`0px 0px 3px ${dropShadowColor}`)).join(",")

    // const topMessageValue = formFields[FORM_FIELD_TOP_ERROR_MESSAGE_VALUE]
    // const showTopMessage = formFields[FORM_FIELD_SHOW_TOP_ERROR_MESSAGE]

    // const topSuccessMessageValue = formFields[FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE]
    // const showTopSuccessMessage = formFields[FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE]

    const vibeCheckLoadingText = (analyzeNervousSystemStatesResult.state === REQUEST_SUCCESS) ? i18n.vibe_check_complete : ((fetchSpotifyMetadataResult.state === REQUEST_SUCCESS) ? i18n.analyzing_lyrics : i18n.reading_track_info)

    const baseSvgDropShadow = `0px 0px 1px ${!!(dropShadowColor) ? dropShadowColor : "#353F6A"}`
    const textShadow = range(4).map(() => (`0px 0px 3px rgba(0, 0, 0, 0.16)`)).join(",")
    const shareSvgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.16))`)).join(" ")
    const svgDropShadow = range(2).map(() => (`drop-shadow(${baseSvgDropShadow})`)).join(" ")
    const displaySvgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1))`)).join(" ")
    const displayTextShadow = range(4).map(() => (`0px 0px 2px rgba(0, 0, 0, 0.12)`)).join(",")

    const shareButtonTextColor = "#ddd"
    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames={"fade-in-500"}
        onExited={() => {}}
      >
        <div style={{position: "fixed", left: 0, top: 0, width: "100vw", height: innerHeight, overflow: "hidden"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, backgroundColor: headerColor, display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 + safeAreaInsets.top, backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", zIndex: 1000}}>
            <div>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                padding={"0px"}
                marginTop={0}
                color={"white"}
                fontFamily={"Varela Round"}
                text={"checkthevibe"}
                showProgressionBar={false}
                dropShadowColor={dropShadowColor}
                dropShadowMultiplier={5}
                onClick={() => window.location = "/"}
              />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{textAlign: "center", marginRight: "min(3vw, 15px)", height: "min(6vw, 30px)", width: "min(6vw, 30px)"}}>
                <div
                  style={{height: "min(6vw, 30px)", width: "min(6vw, 30px)", cursor: "pointer", overflow: "visible", filter: svgDropShadow}}
                  onClick={() => {
                    recordVibeCheckFunnelEventGA(GA_LABEL_CLICKED_SELECT_LANGUAGE)
                    onToggleSelectLanguageModal(true)
                  }}
                >
                  {ChooseLanguageSvg}
                </div>
                <div style={{color: "#eee", height: "min(10px, 2.5vw)", textShadow: displayTextShadow, fontSize: "min(10px, 2.5vw)"}}>
                  {language.toUpperCase()}
                </div>
              </div>
              <div 
                style={{height: "min(6vw, 30px)", width: "min(5vw, 25px)", cursor: "pointer", marginRight: "min(3vw, 15px)", overflow: "visible", filter: svgDropShadow}}
                onClick={() => {
                  recordLinkClickGA("About Page Link")
                  window.location = "/about"
                }}
              >
                <svg className="authentique-svg" height='100px' width='100px'  fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 450" x="0px" y="0px"><g data-name="Calque 2"><g data-name="Calque 2"><circle cx="225" cy="133.5" r="31.25"></circle><path d="M251,219.12v103a26,26,0,0,1-52,0v-103a26,26,0,0,1,52,0Z"></path><path d="M225,0C100.74,0,0,100.74,0,225S100.74,450,225,450,450,349.26,450,225,349.26,0,225,0ZM355.81,355.81A185,185,0,1,1,94.19,94.19,185,185,0,1,1,355.81,355.81Z"></path><path d="M225,0C100.74,0,0,100.74,0,225S100.74,450,225,450,450,349.26,450,225,349.26,0,225,0ZM355.81,355.81A185,185,0,1,1,94.19,94.19,185,185,0,1,1,355.81,355.81Z"></path></g></g></svg>
              </div>
              <a
                href={"https://neurofit.app"}
                target="_blank"
                rel="noopener noreferrer" 
                style={{height: "min(5.5vw, 25px)", width: "min(5.5vw, 25px)", cursor: "pointer", overflow: "visible", filter: svgDropShadow}}
                onClick={() => {
                  recordLinkClickGA("NEUROFIT")
                }}
              >
                <NeuroFitCutoutSvg
                  size={"100%"}
                  color={"#FFFFFF"}
                />
              </a>
              {/*<div
                style={{height: "min(6vw, 30px)", width: "min(5vw, 25px)", cursor: "pointer", overflow: "visible", filter: svgDropShadow}}
                onClick={() => {
                  recordLinkClickGA("Spotify")
                  window.open(CheckthevibeSpotifyAccountUrl, "_blank", "noopener noreferrer")
                }}
              >
                <svg className="authentique-svg" style={{overflow: "visible"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="#fff" d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z" class=""></path></svg>
              </div>*/}
            </div>
          </div>
          <div 
            className={"disable-scrollbars"}
            style={containerStyle}
            ref={elem => this.scrollingContainerRef = elem}
          >
            <div style={{textAlign: "left", height: "100%", width: "100vw"}}>
              <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: "15vh"}}>
                <div style={{width: "85%", maxWidth: 500, minHeight: `calc(${innerHeight}px - 15vh)`}}>
                  <div>
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        timeout={400}
                        classNames={"fade-in-400"}
                        unmountOnExit
                        addEndListener={(node, done) => {
                          node.addEventListener("transitionend", done, false);
                        }}
                        key={vibeCheckResult.state === REQUEST_FETCHING && analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS}
                      >
                        <div style={{marginTop: ((vibeCheckResult.state === REQUEST_UNSTARTED || (vibeCheckResult.state === REQUEST_ERROR) && analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS)) ? "4vh" : "2vh"}}>
                          {(((vibeCheckResult.state === REQUEST_UNSTARTED) || (vibeCheckResult.state === REQUEST_ERROR)) && (analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS)) ? (
                            <div>
                              <div className="shimmer-input-box" style={{backgroundColor: "rgba(0, 0, 0, 0.05)", boxShadow: "0px 0px 0.5px 2px #ffffff", borderRadius: 8, backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <input
                                  type="text"
                                  className={"input" + (!(vibeCheckSearchText) ? " shimmer-input-box" : "")}
                                  style={language === "el" ? {padding: "min(1.5vh, 10px) 2.5%"} : {}}
                                  placeholder={i18n.enter_spotify_song}
                                  autoCapitalize="none"
                                  autoCorrect="false"
                                  autoComplete="off"
                                  spellCheck={false}
                                  value={vibeCheckSearchText}
                                  disabled={vibeCheckResult.state === REQUEST_FETCHING}
                                  onChange={(e) => {
                                    onUpdateVibeCheckSearchText(e.target.value)
                                    onToggleErrorMessage(false)
                                    onTriggerRequest(REQ_FETCH_TRACK_SEARCH_RESULTS)
                                  }}
                                  onPaste={(e) => {
                                    onUpdateVibeCheckSearchText(e.target.value)
                                    onToggleErrorMessage(false)
                                    onTriggerRequest(REQ_FETCH_TRACK_SEARCH_RESULTS)
                                  }}
                                  onFocus={() => recordVibeCheckFunnelEventGA(GA_LABEL_FOCUSED_SPOTIFY_SEARCH_BAR)}
                                />
                                <LoadingLogo
                                  show={vibeCheckSearchResult.state === REQUEST_FETCHING}
                                  hideAnimation
                                  isLoading
                                  fastLoading
                                  responsiveSize={"min(4.5vh, 25px)"}
                                  marginTop={0}
                                  marginRight={5}
                                  color={"white"}
                                  text=""
                                  dropShadowColor={dropShadowColor}
                                  dropShadowMultiplier={5}
                                />
                              </div>
                              <div style={{textAlign: "center"}}>
                                <ErrorPanel
                                  show={(vibeCheckResult.state === REQUEST_ERROR) || (vibeCheckSearchResult.state === REQUEST_ERROR)}
                                  hideCloseButton
                                  message={(vibeCheckResult.state === REQUEST_ERROR) ? i18n[vibeCheckResult.error.error_code] : ((vibeCheckSearchResult.state === REQUEST_ERROR) ? i18n[vibeCheckSearchResult.error.error_code] : i18n.default_error_message)}
                                />
                              </div>
                               <CSSTransition
                                  timeout={400}
                                  classNames={"fade-in-300-delay-100"}
                                  unmountOnExit
                                  in={vibeCheckSearchResult.state === REQUEST_SUCCESS}
                                >
                                  <div>
                                    {(vibeCheckSearchResult.result && vibeCheckSearchResult.result.results.length > 0) ? (
                                      <div className="disable-scrollbars" style={{height: "40vh", paddingTop: "3vh", paddingBottom: "20vh", marginTop: 2}}>
                                        {(vibeCheckSearchResult.result.results.slice(0, 3) || []).map(result => (
                                          <div key={result.id} style={{marginTop: 10}}>
                                              <SearchResultArtistTrackDisplay
                                                isLoaded
                                                displayArtImageUrl={result.album_image_url}
                                                trackTitle={result.track_name}
                                                artistName={result.artist_name}
                                                dropShadowColor={dropShadowColor}
                                                textShadow={displayTextShadow}
                                                onClick={() => {
                                                  onUpdateShowDescription(false)
                                                  onUpdateShowTranscendence(false)
                                                  onUpdateSelectedTrackId(result.id)
                                                  onTriggerRequest(REQ_RUN_VIBECHECK)
                                                }}
                                              />
                                            </div>
                                          ))}
                                          <div style={{marginTop: "1.5vh", fontSize: "min(4.5vw, 20px)", fontFamily: "Nunito", fontWeight: 800, textAlign: "center"}}>
                                            {i18n.tap_a_song}
                                          </div>
                                        </div>
                                      ) : (
                                        (vibeCheckSearchResult.state === REQUEST_SUCCESS) && (
                                            <div style={{fontSize: "min(5vw, 25px)", textAlign: "center", marginTop: "4vh"}}>
                                            {i18n.no_search_results_found}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </CSSTransition>
                            </div>
                          ) : (
                            <div>
                              <ArtistTrackDisplay
                                isLoaded={fetchSpotifyMetadataResult.state === REQUEST_SUCCESS}
                                displayArtImageUrl={fetchSpotifyMetadataResult.result ? fetchSpotifyMetadataResult.result.metadata.album_image_url : ""}
                                trackTitle={fetchSpotifyMetadataResult.result ? fetchSpotifyMetadataResult.result.metadata.track_name : "..."}
                                artistName={fetchSpotifyMetadataResult.result ? fetchSpotifyMetadataResult.result.metadata.artist_name : "..."}
                                artistProfileImageUrl={fetchSpotifyMetadataResult.result ? fetchSpotifyMetadataResult.result.metadata.artist_profile_image_url : ""}
                                textShadow={displayTextShadow}
                              />
                              <LoadingLogo
                                show={vibeCheckResult.state === REQUEST_FETCHING && analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS}
                                hideAnimation={true}
                                isLoading={vibeCheckResult.state === REQUEST_FETCHING && analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS}
                                reverseShimmer
                                responsiveSize={"min(7vw, 35px)"}
                                marginTop={30}
                                color={"white"}
                                fontFamily={"Varela Round"}
                                text={vibeCheckLoadingText}
                                showProgressionBar
                                dropShadowColor={"none"}
                                currentProgressPercentage={vibeCheckProgress.toFixed(0) + "%"}
                                dropShadowMultiplier={5}
                              />
                            </div>
                          )}
                        </div>
                      </CSSTransition>
                    </SwitchTransition>
                    <CSSTransition
                      timeout={900}
                      classNames={"fade-in-400-delay-500"}
                      unmountOnExit
                      in={analyzeNervousSystemStatesResult.state === REQUEST_SUCCESS}
                    >
                      <div style={{marginTop: 25}}>
                        {analyzeNervousSystemStatesResult.result && !!(analyzeNervousSystemStatesResult.result.top_nervous_system_states) && (
                          <NervousSystemEffectsDescription
                            topNervousSystemStates={analyzeNervousSystemStatesResult.result.top_nervous_system_states}
                            textShadow={displayTextShadow}
                            svgDropShadow={displaySvgDropShadow}
                            innerWidth={innerWidth}
                            onClickInfoButton={() => {
                              recordVibeCheckFunnelEventGA(GA_LABEL_CLICKED_SHOW_RESULTS_INFO_BUTTON)
                              onToggleNervousSystemInfoModal(true)
                            }}
                            onClickShareButton={() => {
                              recordVibeCheckFunnelEventGA(GA_LABEL_CLICKED_SHARE_BUTTON)
                              copyToClipboard(analyzeNervousSystemStatesResult.result.share_url)
                              onUpdateBottomSuccessMessage(i18n.copied_result_link)
                              onTriggerRequest(REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE)
                            }}
                          />
                        )}
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0px"}}>
                          <div style={{borderRadius: 10, boxShadow: "0px 0px 2px 1.5px white", width: "100%"}}>
                            <button
                              style={{border: "none", padding: "8px", textDecoration: "none", margin: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", color: "#444", borderRadius: 10, overflow: "hidden", fontFamily: "Nunito", fontWeight: 600, display: "flex", alignItems: "center", justifyContent: "center", textShadow: displayTextShadow}}
                              onClick={() => {
                                recordVibeCheckFunnelEventGA(GA_LABEL_CLICKED_HOME_BUTTON)
                                window.location = "/"
                              }}
                            >
                              <div style={{marginRight: 5, display: "inline-block", height: 20, width: 20, minWidth: 20, transform: "rotate(180deg)"}}>
                                <svg className="authentique-svg" height='100px' width='100px' fill="#444" style={{color: "#444"}} version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style={{enableBackground:"new 0 0 100 100"}}><path d="M95.4,45L66.1,15.7c-2.7-2.7-7.2-2.7-9.9,0c-2.7,2.7-2.7,7.2,0,9.9L73.5,43h-64c-3.9,0-7,3.1-7,7s3.1,7,7,7h64L56.2,74.3    c-2.7,2.7-2.7,7.2,0,9.9c1.4,1.4,3.2,2.1,5,2.1s3.6-0.7,5-2.1L95.4,55c1.3-1.3,2.1-3.1,2.1-5C97.5,48.1,96.8,46.3,95.4,45z"></path></svg>
                              </div>
                              <div style={{fontSize: "min(3vh, 20px)", textShadow: "none", color: "#444", textTransform: "uppercase"}}>
                                {i18n.back_to_start}
                              </div>
                            </button>
                          </div>
                        </div>
                        <div style={{fontSize: "min(4.5vw, 20px)", fontFamily: "Nunito", color: "#ffffff", fontWeight: 800, textAlign: "center"}}>
                          {i18n.created_with_love}
                          <a href={"https://neurofit.app"} target="_blank" rel="noopener noreferrer" style={{color: "#fff", marginLeft: "0.15em", fontWeight: 800}}>{"NEUROFIT"}</a>
                        </div>
                      </div>
                    </CSSTransition>
                  </div>
                </div>
              </div>
              <CSSTransition
                timeout={900}
                classNames={"fade-in-400-delay-500"}
                unmountOnExit
                in={analyzeNervousSystemStatesResult.state === REQUEST_SUCCESS}
              >
                <div style={{padding: `50px 0px calc(min(3vw, 15px) + ${25 + safeAreaInsets.bottom}px) 0px`}}>
                  <div style={{padding: "5px 15px 10px 15px", fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, color: "#ddd", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                    <div style={{display: "inline-block"}}>
                      {"© 2023 NEUROFIT"}
                    </div>
                    <div style={{display: "inline-block"}}>
                      <a href={"https://legal.neurofit.app/#privacy"} target="_blank" rel="noreferrer noopener nofollow" style={{color: "#ddd"}}>
                        {i18n.privacy}
                      </a>
                      <div style={{display: "inline-block", margin: "0px 5px", fontSize: "min(3vw, 15px)"}}>
                        {" • "}
                      </div>
                      <a href={"https://legal.neurofit.app/#terms"} target="_blank" rel="noreferrer noopener nofollow" style={{color: "#ddd"}}>
                        {i18n.terms}
                      </a>
                    </div>
                  </div>
                </div>
              </CSSTransition>
              <div style={{textAlign: "left", width: "100vw", position: "fixed", bottom: 0, left: 0}}>
                <CSSTransition
                  timeout={900}
                  classNames={"fade-in-400-delay-500"}
                  unmountOnExit
                  in={analyzeNervousSystemStatesResult.state !== REQUEST_SUCCESS}
                >
                  <div style={{padding: "5px 15px 10px 15px", fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, color: "#ddd", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                    <div style={{display: "inline-block"}}>
                      {"© 2023 NEUROFIT"}
                    </div>
                    <div style={{display: "inline-block"}}>
                      <a href={"https://legal.neurofit.app/#privacy"} target="_blank" rel="noreferrer noopener nofollow" style={{color: "#ddd"}}>
                        {i18n.privacy}
                      </a>
                      <div style={{display: "inline-block", margin: "0px 5px", fontSize: "min(3vw, 15px)",}}>
                        {" • "}
                      </div>
                      <a href={"https://legal.neurofit.app/#terms"} target="_blank" rel="noreferrer noopener nofollow" style={{color: "#ddd"}}>
                        {i18n.terms}
                      </a>
                    </div>
                  </div>
                </CSSTransition>
                <div style={{backgroundColor: "rgba(255, 255, 255, 0.5)", color: "#444", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", padding: 10, paddingBottom: Math.max(10 + safeAreaInsets.bottom), zIndex: 10000}}>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", opacity: statisticsLoading ? 0.1 : 1.0, transition: "opacity 0.2s linear"}}>
                   <div style={{display: "inline-block", marginRight: 5, width: "min(3.5vw, 16px)", height: "min(3.5vw, 16px)"}}>
                      <CircularProgressbar
                        value={REFRESH_STATISTICS_COUNTDOWN_SECONDS - refreshStatisticsCountdown} 
                        maxValue={REFRESH_STATISTICS_COUNTDOWN_SECONDS}
                        styles={buildStyles({

                          // Can specify path transition in more detail, or remove it entirely
                          pathTransition: `stroke-dashoffset ${(showRefreshStatisticsCountdown) ? `${REFRESH_STATISTICS_COUNTDOWN_SECONDS}` : "0"}s linear, stroke 0.2s linear`,

                          // Colors
                          pathColor: (showRefreshStatisticsCountdown) ? `#444` : `transparent`,
                          // opacity: (refreshSentInvitesCountdown === 5) ? 0.0 : 1.0,
                          trailColor: 'transparent'
                        })}
                        className="circular-progress-bar-align-top"
                      />
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, marginRight: 3}}>
                      {i18n.vibe_checks}
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", marginRight: 10}}>
                      {footerStatistics.vibe_checks_total}
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, marginRight: 3}}>
                      {i18n._24_hours}
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", marginRight: 10}}>
                      {footerStatistics.vibe_checks_24h}
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", fontWeight: 600, marginRight: 3}}>
                      {i18n.users}
                    </div>
                    <div style={{fontSize: "min(3.5vw, 16px)", fontFamily: "Nunito", color: "#444"}}>
                      {footerStatistics.users_total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BottomSuccessMessage
            message={bottomSuccessMessage}
            show={showBottomSuccessMessage}
            safeAreaInsets={safeAreaInsets}
          />
          <SelectLanguageModal
            show={showSelectLanguageModal}
            innerHeight={innerHeight}
            onHide={() => onToggleSelectLanguageModal(false)}
          />
          <NervousSystemStateInfoModal
            show={showNervousSystemInfoModal}
            innerHeight={innerHeight}
            onHide={() => onToggleNervousSystemInfoModal(false)}
          />
          <PWAPrompt
            promptOnVisit={2}
            copyBody={"This website has app functionality. Add it to your home screen for quicker access."}
            copyShareButtonLabel={"1) Press the 'Share' button below."}
            permanentlyHideOnDismiss={false}
          />
          <canvas id="gradient-canvas" style={{position: "fixed", top: 0, left: 0, height: innerHeight, width: "100%", zIndex: -1}}>
          </canvas>
        </div>
      </CSSTransition>
    )
  }
}

export default VibeCheckPage;