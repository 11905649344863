import React from 'react';

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_ERROR
} from '../constants/requestStates';

const AppLoadingPage = ({
  height,
  appLoadResultState,
  appLoadErrorMessage,
  retryButtonText,
  // Actions
  onReloadApp,
}) => {
  return (
    <div style={{height: height, width: "100vw", position: "absolute", top: 0, left: 0, justifyContent: "center", display: "flex", alignItems: "center"}}>
      {((appLoadResultState === REQUEST_FETCHING) || (appLoadResultState === REQUEST_UNSTARTED)) && (
        <div style={{width: 120, height: 120, display: "inline-block"}}> 
          <svg style={{overflow: "visible", height: "100%", width: "100%"}} width="120px" height="120px" viewBox="0 0 94 94" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><rect id="path-1" x="0" y="0" width="94" height="94"></rect></defs><g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="balance-ring"><mask id="mask-2" fill="white"><use xlinkHref="#path-1"></use></mask><g id="Mask"></g><path d="M47,87 C69.09139,87 87,69.09139 87,47 C87,24.90861 69.09139,7 47,7 C24.90861,7 7,24.90861 7,47 C7,69.09139 24.90861,87 47,87 Z" id="Oval" stroke="#FFFFFF" strokeWidth="10" strokeDasharray="68,181" mask="url(#mask-2)" transform="translate(47, 47) rotate(228) translate(-47, -47)"></path><path d="M47,87 C69.09139,87 87,69.09139 87,47 C87,24.90861 69.09139,7 47,7 C24.90861,7 7,24.90861 7,47 C7,69.09139 24.90861,87 47,87 Z" id="Oval" stroke="#FFFFFF" strokeWidth="10" strokeDasharray="68,181" mask="url(#mask-2)" transform="translate(47, 47) rotate(110) translate(-47, -47)"></path><path d="M47,87 C69.09139,87 87,69.09139 87,47 C87,24.90861 69.09139,7 47,7 C24.90861,7 7,24.90861 7,47 C7,69.09139 24.90861,87 47,87 Z" id="Oval" stroke="#FFFFFF" strokeWidth="10" strokeDasharray="68,237" mask="url(#mask-2)" transform="translate(47, 47) rotate(-11) translate(-47, -47)"></path></g></g></svg>
        </div>
      )}
      {(appLoadResultState === REQUEST_ERROR) && (
        <div className="header-text">
          <div style={{marginBottom: "3vh"}}>{appLoadErrorMessage}</div>
          <button
            className="confirm-button confirm-button-dark"
            onClick={onReloadApp}
          >
            {retryButtonText}
          </button>
        </div>
      )}
    </div>
  )
}

export default AppLoadingPage;