import React from 'react'


import {
  i18n,
  recordLinkClickGA,
  languageNames,
  setPrioritizedLanguageCodeCookie,
} from '../../utils'

import { CSSTransition } from 'react-transition-group';

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#444", borderRadius: "50%", boxShadow: "0px 0px 10px #444", border: "none"}
const friendshipHeaderStyle = {
  color: "#444",
  fontFamily: "Nunito", fontWeight: 600,
  fontSize: 16,
  textAlign: "center"
}
const friendshipSubHeaderStyle = {
  color: "#999999",
  fontSize: 13,
  fontFamily: "Varela Round",
  textAlign: "center"
}
const friendButtonStyle = {
  fontFamily: "Varela Round",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  fontSize: 14,
  color: "#444",
  border: "none",
  backgroundColor: "transparent",
  height: 50,
  textAlign: "left",
  width: "100%",
  border: "none",
}
const buttonIconStyle = {
  display: "inline-block",
  width: 30,
  height: 30,
  marginRight: 10
}

const buttonSubTextStyle = {
  fontFamily: "Nunito", fontWeight: 600,
  display: "inline-block",
  fontSize: 14,
  color: "#999999",
  backgroundColor: "transparent",
  textAlign: "left",
  border: "none",
}

const subHeaderStyle = {
  color: "#999999",
  fontSize: 14,
  fontFamily: "Nunito", fontWeight: 600,
  textAlign: "center"
}

const SelectLanguageModal = ({
  // State
  show,
  onHide,
  innerHeight
}) => {
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames=""
      unmountOnExit
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "transparent",
          backdropFilter: show ? "blur(3px)" : "none",
          WebkitBackdropFilter: show ? "blur(3px)" : "none",
          zIndex: 1000000
        }}
        onClick={onHide}
      >
        <CSSTransition
          in={show}
          timeout={300}
          classNames="slide-from-bottom"
          unmountOnExit
          appear
        >
          <div 
            id={"consciousness-info-modal"}
            style={{
              position: 'absolute',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              padding: '20px',
              left: 0,
              right: 0,
              width: "calc(100% - 40px)",
              maxWidth: "500px",
              margin: "0px auto",
              bottom: 0,
              height: Math.min(650, innerHeight - 100),
              borderRadius: 20
            }}
            onClick={e => e.stopPropagation()}
          >
            <span
              className="close"
              onClick={onHide}
            >
              &times;
            </span>
            <div>
              <div style={{"textAlign": "center"}}>
                <div style={{marginBottom: 20}}>
                  <div style={friendshipHeaderStyle}>
                    {i18n.choose_your_language}
                  </div>
                </div>
                <div className="fields-separator" />
                {languageNames.map(lang => (
                  <div key={lang.language_code}>
                    <button
                      style={{...friendButtonStyle, fontFamily: "Nunito", fontWeight: 600}}
                      onClick={() => {
                        setPrioritizedLanguageCodeCookie(lang.language_code)
                        window.location.reload()
                      }}
                    >
                      <div>
                        <div>
                          {lang.display_name}
                        </div>
                      </div>
                    </button>
                    <div className="fields-separator" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}

export default SelectLanguageModal