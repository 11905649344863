import {
  COMMON_TRIGGER_REQUEST,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_INNER_WIDTH,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_VIBE_CHECK_PROGRESS,
  COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS,
  COMMON_UPDATE_SHOW_DESCRIPTION,
  COMMON_UPDATE_SHOW_TRANSCENDENCE,
  COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE,
  COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE,
  COMMON_UPDATE_CACHED_RESULT_SHORT_CODE,
  COMMON_UPDATE_STATISTICS,
  COMMON_UPDATE_PAGE_IS_VISIBLE,
  COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN,
  COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL,
  COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL,
  COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL,
  COMMON_UPDATE_SELECTED_TRACK_ID,
  COMMON_RESET_STATE,
} from '../constants/actionTypes';

import {
  REQUEST_SUCCESS,
  REQUEST_FETCHING,
  REQUEST_ERROR,
} from '../constants/requestStates';

export const resetCommon = () => {
  return {
    type: COMMON_RESET_STATE
  }
};

export const commonUpdateVibeCheckSearchText = searchText => {
  return {
    type: COMMON_UPDATE_VIBE_CHECK_SEARCH_TEXT,
    payload: searchText
  }
}

export const commonUpdateSelectedTrackId = trackId => {
  return {
    type: COMMON_UPDATE_SELECTED_TRACK_ID,
    payload: trackId
  }
}

export const commonTriggerRequest = (key) => {
  return {
    type: COMMON_TRIGGER_REQUEST + key,
    payload: {
      key,
    }
  }
};

export const commonUpdateRequestState = (key, state, result=undefined) => {
  let payload = {
    state,
  };
  if (!!(result)) {
    if (state === REQUEST_SUCCESS || state === REQUEST_FETCHING) {
      payload.result = result
    }
    if (state === REQUEST_ERROR) {
      payload.error = result;
    }
  }
  return {
    type: COMMON_UPDATE_REQUEST_STATE,
    key,
    payload,
  }
}

export const commonUpdateInnerHeight = (innerHeight) => {
  return {
    type: COMMON_UPDATE_INNER_HEIGHT,
    payload: innerHeight,
  }
}

export const commonUpdateInnerWidth = innerWidth => {
  return {
    type: COMMON_UPDATE_INNER_WIDTH,
    payload: innerWidth,
  }
}

export const commonUpdateSafeAreaInsets = (safeAreaInsets) => {
  return {
    type: COMMON_UPDATE_SAFE_AREA_INSETS,
    payload: safeAreaInsets,
  }
}

export const commonUpdateVibeCheckProgress = progress => {
  return {
    type: COMMON_UPDATE_VIBE_CHECK_PROGRESS,
    payload: progress
  }
} 

export const commonUpdateMaxVibeCheckProgress = progress => {
  return {
    type: COMMON_UPDATE_MAX_VIBE_CHECK_PROGRESS,
    payload: progress
  }
} 

export const commonUpdateShowDescription = show => {
  return {
    type: COMMON_UPDATE_SHOW_DESCRIPTION,
    payload: show
  }
}


export const commonUpdateShowTranscendence = show => {
  return {
    type: COMMON_UPDATE_SHOW_TRANSCENDENCE,
    payload: show
  }
}

export const commonUpdateShowBottomSuccessMessage = show => {
  return {
    type: COMMON_UPDATE_SHOW_BOTTOM_SUCCESS_MESSAGE,
    payload: show
  }
}

export const commonUpdateBottomSuccessMessage = message => {
  return {
    type: COMMON_UPDATE_BOTTOM_SUCCESS_MESSAGE,
    payload: message
  }
}

export const commonUpdateCachedResultShortCode = code => {
  return {
    type: COMMON_UPDATE_CACHED_RESULT_SHORT_CODE,
    payload: code
  }
}

export const commonUpdateVibeCheckStatistics = statistics => {
  return {
    type: COMMON_UPDATE_STATISTICS,
    payload: statistics
  }
}

export const commonUpdatePageIsVisible = isVisible => {
  return {
    type: COMMON_UPDATE_PAGE_IS_VISIBLE,
    payload: isVisible
  }
}

export const commonUpdateShowRefreshStatisicsCountdown = showCountdown => {
  return {
    type: COMMON_UPDATE_SHOW_REFRESH_STATISTICS_COUNTDOWN,
    payload: showCountdown,
  }
}

export const commonUpdateRefreshStatisticsCountdown = countdownSeconds => {
  return {
    type: COMMON_UPDATE_REFRESH_STATISTICS_COUNTDOWN,
    payload: countdownSeconds
  }
}


export const commonUpdateShowNervousSystemInfoModal = show => {
  return {
    type: COMMON_UPDATE_SHOW_NERVOUS_SYSTEM_INFO_MODAL,
    payload: show
  }
}

export const commonUpdateShowSelectLanguageModal = show => {
  return {
    type: COMMON_UPDATE_SHOW_SELECT_LANGUAGE_MODAL,
    payload: show
  }
}

export const commonUpdateShowEmailSubscriptionModal = show => {
  return {
    type: COMMON_UPDATE_SHOW_EMAIL_SUBSCRIPTION_MODAL,
    payload: show
  }
}

