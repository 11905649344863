import React from 'react'

import { CSSTransition } from 'react-transition-group';

const errorMessageStyle = {color: "red", textAlign: "center", marginTop: 5}

const BottomSuccessMessage = ({
  // State
  show,
  message,
  safeAreaInsets,
}) => {
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="slide-from-bottom-success-message"
      unmountOnExit
      appear
    >
      <div style={{left: 0, bottom: safeAreaInsets.bottom || 0, position: "fixed", width: "100%", paddingTop: 5, paddingBottom: 5, zIndex: 10000000000, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#33ad61"}}>
        <div style={{textAlign: "center", padding: "0px 10px", color: "white", fontFamily: "Varela Round", fontSize: 12, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
          {message}
        </div>
      </div>
    </CSSTransition>
  )
}

export default BottomSuccessMessage