import React from 'react'


import {
  i18n,
  recordLinkClickGA,
} from '../../utils'

import {
  nervousSystemStates,
} from '../../constants/nervousSystemStates'

import { CSSTransition } from 'react-transition-group';

import FaqSection from '../common/FaqSection'

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#444", borderRadius: "50%", boxShadow: "0px 0px 10px #444", border: "none"}
const headerStyle = {
  color: "#444",
  fontFamily: "Varela Round", fontWeight: 800,
  fontSize: 18,
  textAlign: "center"
}

const ctaStyle = {
  color: "#444",
  fontFamily: "Varela Round", fontWeight: 600,
  fontSize: 15,
  textAlign: "center"
}
const subHeaderStyle = {
  color: "#999999",
  fontSize: 15,
  fontFamily: "Varela Round",
  textAlign: "center"
}

const vibeScoreBreakdownSections = [
  {
    "title_key": "vibe_score_excellent",
    "score_levels": "(90-100)",
    "description_key": "vibe_score_excellent_description"
  },
  {
    "title_key": "vibe_score_great",
    "score_levels": "(80-89)",
    "description_key": "vibe_score_great_description"
  },
  {
    "title_key": "vibe_score_good",
    "score_levels": "(70-79)",
    "description_key": "vibe_score_good_description"
  },
  {
    "title_key": "vibe_score_questionable",
    "score_levels": "(60-69)",
    "description_key": "vibe_score_questionable_description"
  },
  {
    "title_key": "vibe_score_pause",
    "score_levels": "(40-59)",
    "description_key": "vibe_score_pause_description"
  },
  {
    "title_key": "vibe_score_skip",
    "score_levels": "(0-39)",
    "description_key": "vibe_score_skip_description"
  },

]

const NervousSystemStateInfoModal = ({
  // State
  show,
  onHide,
  innerHeight
}) => {
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames=""
      unmountOnExit
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "transparent",
          backdropFilter: show ? "blur(3px)" : "none",
          WebkitBackdropFilter: show ? "blur(3px)" : "none",
          zIndex: 1000000
        }}
        onClick={onHide}
      >
        <CSSTransition
          in={show}
          timeout={300}
          classNames="slide-from-bottom"
          unmountOnExit
          appear
        >
          <div 
            id={"consciousness-info-modal"}
            style={{
              position: 'absolute',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              padding: '20px',
              left: 0,
              right: 0,
              width: "calc(100% - 40px)",
              maxWidth: "500px",
              margin: "0px auto",
              bottom: 0,
              height: Math.min(650, innerHeight - 100),
              borderRadius: 20
            }}
            onClick={e => e.stopPropagation()}
          >
            <span
              className="close"
              onClick={onHide}
            >
              &times;
            </span>
            <div>
              <div style={{"textAlign": "center"}}>
                <div style={{marginBottom: 20}}>
                  <div style={headerStyle}>
                    {i18n.the_nervous_system_states}
                  </div>
                  <div style={{...subHeaderStyle, marginTop: 10, whiteSpace: "pre-wrap"}}>
                    {i18n.six_nervous_system_states_explanation}
                  </div>
                  <div style={{...ctaStyle, marginTop: 20}}>
                    {i18n.tap_nervous_system_state_explanation}
                  </div>
                </div>
                <div className="fields-separator" />
                {nervousSystemStates.map(s => (
                  <div key={s.name}>
                    <FaqSection
                      headerText={i18n.nervous_system_states[s.name].display_name}
                      expandedText={i18n.nervous_system_states[s.name].info_description}
                      color={s.color}
                    />
                    <div className="fields-separator" />
                  </div>
                ))}
                <div style={{margin: "60px 0px 20px 0px"}}>
                  <div style={headerStyle}>
                    {i18n.vibe_score_breakdown}
                  </div>
                  <div style={{...subHeaderStyle, marginTop: 20}}>
                    {i18n.vibe_score_breakdown_explanation}
                  </div>
                  <div style={{...ctaStyle, marginTop: 20}}>
                    {i18n.tap_vibe_score_explanation}
                  </div>
                </div>
                <div className="fields-separator" />
                {vibeScoreBreakdownSections.map(section => (
                  <div key={section.title_key}>
                    <FaqSection
                      headerText={`${i18n[section.title_key]} ${section.score_levels}`}
                      expandedText={i18n[section.description_key]}
                    />
                    <div className="fields-separator" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}

export default NervousSystemStateInfoModal