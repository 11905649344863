import { call, put, all, select, takeLatest, debounce } from 'redux-saga/effects'
import agent from './agent'
import {store} from './store';

import {
  isProduction,
  getCsrfTokenFromCookies,
  recordVibeCheckFunnelEventGA,
  getRandomInt,
  language,
  SetGlobalI18N,
  SpotifySongUrlPrefix,
} from './utils';

import {
  COMMON_TRIGGER_REQUEST,
} from './constants/actionTypes';


import {
  REQ_FETCH_TRACK_SEARCH_RESULTS,
  REQ_RUN_VIBECHECK,
  REQ_FETCH_SPOTIFY_TRACK_METADATA,
  REQ_ANALYZE_TRACK_VIBRATION,
  REQ__ACTION_PERIODICALLY_UPDATE_VIBE_CHECK_PROGRESS,
  REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE,
  REQ_FETCH_CACHED_VIBECHECK_RESULT,
  REQ_FETCH_VIBECHECK_STATISTICS,
  REQ_FETCH_I18N,
} from './constants/requestTypes'


import {
  commonTriggerRequest,
  commonUpdateRequestState,
  commonUpdateVibeCheckProgress,
  commonUpdateMaxVibeCheckProgress,
  commonUpdateShowBottomSuccessMessage,
  commonUpdateVibeCheckStatistics,
  commonUpdateShowRefreshStatisicsCountdown,
  commonUpdateRefreshStatisticsCountdown,
} from './actions/common'

import {
  GA_LABEL_SEARCH_QUERY_TRIGGERED,
  GA_LABEL_SEARCH_QUERY_COMPLETED,
  GA_LABEL_VIBE_CHECK_TRIGGERED,
  GA_LABEL_VIBE_CHECK_RETRIEVED_SPOTIFY_METADATA,
  GA_LABEL_VIBE_CHECK_TRIGGERED_ANALYZE_TRACK_LYRICS,
  GA_LABEL_VIBE_CHECK_SUCCEEDED,
} from './constants/gaLabels'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from './constants/requestStates'

import {
  COMMON_VIBE_CHECK_SEARCH_TEXT,
  COMMON_VIBE_CHECK_PROGRESS,
  COMMON_MAX_VIBE_CHECK_PROGRESS,
  COMMON_CACHED_RESULT_SHORT_CODE,
  COMMON_PAGE_IS_VISIBLE,
  COMMON_SELECTED_TRACK_ID,
} from './constants/commonState'


export const REFRESH_STATISTICS_COUNTDOWN_SECONDS = 7


///////////////
// Selectors //
///////////////

// Common
const getVibeCheckSearchTextFromCommonState = state => state.common[COMMON_VIBE_CHECK_SEARCH_TEXT]
const getVibeCheckProgressFromCommonState = state => state.common[COMMON_VIBE_CHECK_PROGRESS]
const getMaxVibeCheckProgressFromCommonState = state => state.common[COMMON_MAX_VIBE_CHECK_PROGRESS]
const getRunVibeCheckResultFromCommonState = state => state.common[REQ_RUN_VIBECHECK]
const getFetchSpotifyTrackMetadataResultFromCommonState = state => state.common[REQ_FETCH_SPOTIFY_TRACK_METADATA]
const getAnalyzeTrackVibrationResultFromCommonState = state => state.common[REQ_ANALYZE_TRACK_VIBRATION]
const getCachedVibecheckResultShortCodeFromCommonState = state => state.common[COMMON_CACHED_RESULT_SHORT_CODE]
const getCachedVibecheckResultFromCommonState = state => state.common[REQ_FETCH_CACHED_VIBECHECK_RESULT]
const getVibecheckStatisticsResultFromCommonState = state => state.common[REQ_FETCH_VIBECHECK_STATISTICS]
const getSearchTracksResultFromCommonState = state => state.common[REQ_FETCH_TRACK_SEARCH_RESULTS]
const getI18NResultFromCommonState = state => state.common[REQ_FETCH_I18N]
const getPageIsVisibleFromCommonState = state => state.common[COMMON_PAGE_IS_VISIBLE]
const getSelectedTrackIdFromCommonState = state => state.common[COMMON_SELECTED_TRACK_ID]

//////////
// Util //
//////////
function* baseRequestWorker(action, reqFunc, reqParams, gaSuccessEventLabel, updateRequestState, gaTriggeredEventLabel) {
  const reqType = action.payload.key;
  try {
    if (gaTriggeredEventLabel) {
      recordVibeCheckFunnelEventGA(gaTriggeredEventLabel)
    }
    yield put(updateRequestState(reqType, REQUEST_FETCHING))
    const result = yield call(reqFunc, reqParams)
    if (reqType === REQ_FETCH_I18N) {
      SetGlobalI18N(result, language)
    }
    yield put(updateRequestState(reqType, REQUEST_SUCCESS, result))
    if (gaSuccessEventLabel) {
      recordVibeCheckFunnelEventGA(gaSuccessEventLabel)
    }
  } catch (err) {
    yield put(updateRequestState(reqType, REQUEST_ERROR, err))
  }
}

const delay = (ms) => new Promise(res => setTimeout(res, ms))



////////////////////////////////
// Fetch Track Search Results //
////////////////////////////////
function* fetchTrackSearchResultsWorker(action) {
  const rawQuery = yield select(getVibeCheckSearchTextFromCommonState)

  if (!(rawQuery) || (rawQuery.length < 3)) {
    yield put(commonUpdateRequestState(REQ_FETCH_TRACK_SEARCH_RESULTS, REQUEST_UNSTARTED, {}))
    return
  }

  const previousSearchResult = yield select(getSearchTracksResultFromCommonState)


  yield put(commonUpdateRequestState(REQ_FETCH_TRACK_SEARCH_RESULTS, REQUEST_FETCHING, previousSearchResult.result || undefined))
  yield delay(500)
  yield put(commonUpdateRequestState(REQ_FETCH_TRACK_SEARCH_RESULTS, REQUEST_FETCHING, undefined))

  const query = rawQuery.trim().replace(new RegExp(' ', 'g'), "+")
  yield baseRequestWorker(
    action,
    agent.VibeCheckBackend.getSpotifyTrackSearchResults,
    {query},
    GA_LABEL_SEARCH_QUERY_TRIGGERED,
    commonUpdateRequestState,
    GA_LABEL_SEARCH_QUERY_COMPLETED,
  )
}
function* fetchTrackSearchResultsSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_TRACK_SEARCH_RESULTS, fetchTrackSearchResultsWorker);
}

////////////////////
// Run Vibe Check //
////////////////////
function* runVibeCheckWorker(action) {
  const trackId = yield select(getSelectedTrackIdFromCommonState)
  const vibeCheckUrl = SpotifySongUrlPrefix + trackId

  yield put(commonUpdateVibeCheckProgress(10.0))
  yield put(commonUpdateMaxVibeCheckProgress(40.0))
  yield put(commonUpdateRequestState(REQ_RUN_VIBECHECK, REQUEST_FETCHING, {}))

  // Update progress on an interval.
  // Clear interval on errors etc.
  yield put(commonTriggerRequest(REQ__ACTION_PERIODICALLY_UPDATE_VIBE_CHECK_PROGRESS))

  yield delay(3000)

  // Reset Search Request after results have been hidden.
  yield put(commonUpdateRequestState(REQ_FETCH_TRACK_SEARCH_RESULTS, REQUEST_UNSTARTED, {}))



  yield baseRequestWorker(
    {payload: {key: REQ_FETCH_SPOTIFY_TRACK_METADATA}},
    agent.VibeCheckBackend.getTrackMetadata,
    {vibe_check_url: vibeCheckUrl},
    GA_LABEL_VIBE_CHECK_TRIGGERED,
    commonUpdateRequestState,
    GA_LABEL_VIBE_CHECK_RETRIEVED_SPOTIFY_METADATA,
  )

  const trackMetadataRequestResult = yield select(getFetchSpotifyTrackMetadataResultFromCommonState)
  let vibecheck_token;
  if (trackMetadataRequestResult.state === REQUEST_ERROR) {
    yield put(commonUpdateRequestState(REQ_RUN_VIBECHECK, REQUEST_ERROR, trackMetadataRequestResult.error))
    yield put(commonUpdateRequestState(REQ_FETCH_SPOTIFY_TRACK_METADATA, REQUEST_UNSTARTED, {}))
    yield put(commonUpdateVibeCheckProgress(0.0))
    return
  } else {
    yield put(commonUpdateVibeCheckProgress(40.0))
    yield put(commonUpdateMaxVibeCheckProgress(100.0))
    vibecheck_token = trackMetadataRequestResult.result.token
  }

  yield delay(3000)

  yield baseRequestWorker(
    {payload: {key: REQ_ANALYZE_TRACK_VIBRATION}},
    agent.VibeCheckBackend.analyzeTrackVibration,
    {vibecheck_token},
    GA_LABEL_VIBE_CHECK_TRIGGERED_ANALYZE_TRACK_LYRICS,
    commonUpdateRequestState,
    GA_LABEL_VIBE_CHECK_SUCCEEDED,
  )

  const trackVibrationRequestResult = yield select(getAnalyzeTrackVibrationResultFromCommonState)
  if (trackVibrationRequestResult.state === REQUEST_ERROR) {
    yield put(commonUpdateRequestState(REQ_RUN_VIBECHECK, REQUEST_ERROR, trackVibrationRequestResult.error))
    yield put(commonUpdateRequestState(REQ_FETCH_SPOTIFY_TRACK_METADATA, REQUEST_UNSTARTED, {}))
    yield put(commonUpdateRequestState(REQ_ANALYZE_TRACK_VIBRATION, REQUEST_UNSTARTED, {}))
    yield put(commonUpdateVibeCheckProgress(0.0))
    return
  } else {
    yield put(commonUpdateRequestState(REQ_RUN_VIBECHECK, REQUEST_UNSTARTED, {}))
    yield put(commonUpdateVibeCheckProgress(100.0))
  }
}
function* runVibeCheckSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_RUN_VIBECHECK, runVibeCheckWorker);
}



function* periodicallyUpdateProgressWorker(action) {
  while (true) {
    const trackVibrationRequestResult = yield select(getAnalyzeTrackVibrationResultFromCommonState)
    const runVibeCheckRequestResult = yield select(getRunVibeCheckResultFromCommonState)
    if ((trackVibrationRequestResult.state === REQUEST_SUCCESS) || (runVibeCheckRequestResult.state !== REQUEST_FETCHING)) {
      break
    }

    const progress = yield select(getVibeCheckProgressFromCommonState)
    const maxProgress = yield select(getMaxVibeCheckProgressFromCommonState)
    const newProgress = progress + ((maxProgress - progress) * 0.35)
    yield put(commonUpdateVibeCheckProgress(newProgress))
    yield delay(getRandomInt(1000, 1500))
  }
}
function* periodicallyUpdateProgressSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ__ACTION_PERIODICALLY_UPDATE_VIBE_CHECK_PROGRESS, periodicallyUpdateProgressWorker);
}

function* flashBottomSuccessMessageWorker(action) {
  yield put(commonUpdateShowBottomSuccessMessage(true))
  yield delay(3000)
  yield put(commonUpdateShowBottomSuccessMessage(false))

}
function* flashBottomSuccessMessageSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE, flashBottomSuccessMessageWorker);
}


///////////////////////////////////
// Fetch Cached Vibecheck Result //
///////////////////////////////////
function* fetchCachedVibecheckResultWorker(action) {
  const short_code = yield select(getCachedVibecheckResultShortCodeFromCommonState)

  yield baseRequestWorker(
    action,
    agent.VibeCheckBackend.getCachedVibecheckResult,
    {short_code},
    undefined,
    commonUpdateRequestState,
  )

  const requestResult = yield select(getCachedVibecheckResultFromCommonState)
  if (requestResult.state === REQUEST_SUCCESS) {
    yield put(commonUpdateRequestState(REQ_FETCH_SPOTIFY_TRACK_METADATA, REQUEST_SUCCESS, {"metadata": requestResult.result.spotify_track_metadata}))
    yield put(commonUpdateRequestState(REQ_ANALYZE_TRACK_VIBRATION, REQUEST_SUCCESS, requestResult.result.vibe_check_metadata))
    yield put(commonUpdateRequestState(REQ_RUN_VIBECHECK, REQUEST_UNSTARTED, {}))
    yield put(commonUpdateVibeCheckProgress(100.0))
  } else if (requestResult.state === REQUEST_ERROR) {

  }
}
function* fetchCachedVibecheckResultSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_CACHED_VIBECHECK_RESULT, fetchCachedVibecheckResultWorker);
}


////////////////////////////////
// Fetch Vibecheck Statistics //
////////////////////////////////
function* fetchVibecheckStatisticsWorker(action) {

  while (true) {
    // TODO: Implement
    const pageVisible = yield select(getPageIsVisibleFromCommonState)
    if (!pageVisible) {
      break
    }

    yield put(commonUpdateShowRefreshStatisicsCountdown(false))
    yield delay(300)
    yield put(commonUpdateRefreshStatisticsCountdown(REFRESH_STATISTICS_COUNTDOWN_SECONDS))

    yield baseRequestWorker(
      action,
      agent.VibeCheckBackend.getVibecheckStatistics,
      {},
      undefined,
      commonUpdateRequestState,
    )

    const requestResult = yield select(getVibecheckStatisticsResultFromCommonState)
    if (requestResult.state === REQUEST_SUCCESS) {
      yield put(commonUpdateVibeCheckStatistics(requestResult.result))
    }

    yield put(commonUpdateShowRefreshStatisicsCountdown(true))
    yield put(commonUpdateRefreshStatisticsCountdown(0))

    // TODO: Implement countup ring like in partner.alma.am/app
    yield delay(REFRESH_STATISTICS_COUNTDOWN_SECONDS * 1000)
  }
}
function* fetchVibecheckStatisticsSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_VIBECHECK_STATISTICS, fetchVibecheckStatisticsWorker);
}


////////////////
// Fetch I18N //
////////////////
function* fetchI18NWorker(action) {
  const language_code = language;
  yield baseRequestWorker(
    action,
    agent.VibeCheckBackend.getI18NFile,
    {language_code},
    undefined,
    commonUpdateRequestState,
  )
}
function* fetchI18NSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_I18N, fetchI18NWorker);
}


export default function* rootSaga() {
  yield all([
    // Common
    fetchTrackSearchResultsSaga(),
    runVibeCheckSaga(),
    periodicallyUpdateProgressSaga(),
    flashBottomSuccessMessageSaga(),
    fetchCachedVibecheckResultSaga(),
    fetchVibecheckStatisticsSaga(),
    fetchI18NSaga(),
  ])
}
