import React from 'react'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import LoadingLogo from './LoadingLogo'

import {
  formatContentDuration,
} from '../../utils'

import millify from "millify";

import {flag} from 'country-emoji'

const tagsStyle = {display: "flex", justifyContent: "left", alignItems: "center", flexWrap: "wrap", flexDirection: "row"}
const tagStyle = {fontFamily: "Varela Round", fontSize: 12, padding: "1px 1px", color: "#eeeeee", opacity: 0.8, display: "inline-block", margin: "4px 4px 4px 0px"}
const tagSeparatorStyle = {color: "#cccccc", opacity: 1.0}
const tagCategoryNameStyle = {opacity: 0.8, height: 22, width: 22, color: "#cccccc", margin: 5}

const contentTitleTextStyle = {
    // maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: "calc(100% - 15px)",
    fontFamily: "Nunito",
    fontWeight: 800,
    fontSize: "min(5vw, 21px)"
  };

const ArtistTrackDisplay = ({
  // State
  isLoaded,
  displayArtImageUrl,
  trackTitle,
  artistName,
  artistProfileImageUrl
}) => {

  return (
    <div style={{minWidth: "min(75%, 400px)", height: "min(100%, 450px)", padding: "0px"}}>
      <div
        style={{color: "#444", fontFamily: "Varela Round", fontWeight: 800, border: "none", fontSize: 14, width: "100%", height: "100%", borderRadius: 10, backgroundColor: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)", boxShadow: "0px 0px 2px 1.5px white", overflow: "visible", display: "block", padding: 0}}
      >
        <div style={{padding: 10, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              timeout={300}
              classNames={"fade-in-300"}
              unmountOnExit
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              key={isLoaded}
            >
              <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                <div style={{height: "min(15vw, 70px)", width: "min(15vw, 70px)", minWidth: "min(15vw, 70px)", backgroundImage: `url(${displayArtImageUrl})`, backgroundColor: "#ccc", borderRadius: 8, backgroundPosition: "center", backgroundSize: "cover", boxShadow: "0px 0px 1.5px 0.5px #ffffff"}}>
                </div>
                <div style={{marginLeft: 15, width: "100%"}}>
                  <div style={{...contentTitleTextStyle}}>
                    {trackTitle}
                  </div>
                  <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                    <div style={{display: "inline-block", height: "min(25px, 5vw)", width: "min(25px, 5vw)", minWidth: 20, minHeight: 20, borderRadius: "50%", overflow: "hidden", marginRight: 5}}>
                      <img src={artistProfileImageUrl} style={{height: "100%", height: "100%", minWidth: "100%", minHeight: "100%", maxWidth: "100%", maxHeight: "100%", objectFit: "cover", backgroundColor: "#ccc"}} />
                    </div>
                    <div style={{display: "inline-block", height: "min(5vw, 20px)", lineHeight: "min(5vw, 20px)", fontWeight: 800, fontSize: "min(4vw, 20px)", marginTop: 3, width: "calc(100% - 30px)", color: "#444", overflow: "hidden", "whiteSpace": "nowrap", textOverflow: "ellipsis"}}>
                      {artistName}
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  )
}

export default ArtistTrackDisplay

